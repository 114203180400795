import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  FaSearch, FaMapMarkerAlt, FaClock, FaUtensils, FaTruck, 
  FaStar, FaRobot, FaTimes, FaMicrophone, FaSpinner,
  FaArrowRight, FaHistory, FaBookmark, FaRegLightbulb,
  FaHeart, FaLeaf, FaFire, FaCrown
} from 'react-icons/fa';

const Hero = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [recentSearches] = useState([
    'Butter Chicken', 'South Indian Thali', 'Weekly Meal Plan', 'Healthy Options'
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [aiTypingText, setAiTypingText] = useState('');
  const navigate = useNavigate();

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6 }
    }
  };
  const handleSearch = (query) => {
    if (!query.trim()) return;
    
    // Track the search query
    const recentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
    const updatedSearches = [query, ...recentSearches.filter(s => s !== query)].slice(0, 5);
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
    
    // Navigate to search page
    navigate(`/search?q=${encodeURIComponent(query)}`);
    setIsSearchFocused(false);
    setSearchQuery('');
    
    // Clear AI typing text
    setAiTypingText('');
  };

  const stats = [
    { icon: FaUtensils, value: '50+', label: 'Menu Items', color: 'from-purple-500 to-indigo-600' },
    { icon: FaClock, value: '30min', label: 'Avg. Delivery Time', color: 'from-green-500 to-emerald-600' },
    { icon: FaStar, value: '4.8', label: 'Customer Rating', color: 'from-yellow-500 to-orange-600' },
    { icon: FaTruck, value: '1000+', label: 'Daily Deliveries', color: 'from-pink-500 to-rose-600' }
  ];

  // Enhanced AI suggestion categories
  const aiSuggestionCategories = {
    dietary: { icon: FaLeaf, color: 'text-green-500' },
    popular: { icon: FaFire, color: 'text-orange-500' },
    premium: { icon: FaCrown, color: 'text-yellow-500' },
    healthy: { icon: FaHeart, color: 'text-red-500' }
  };

  // Simulated AI typing effect
  const simulateAiTyping = useCallback((text) => {
    let i = 0;
    const interval = setInterval(() => {
      setAiTypingText(text.substring(0, i));
      i++;
      if (i > text.length) {
        clearInterval(interval);
        setTimeout(() => setAiTypingText(''), 2000);
      }
    }, 50);
    return () => clearInterval(interval);
  }, []);

  // Enhanced AI search suggestions
  useEffect(() => {
    if (searchQuery.trim() && isSearchFocused) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        const suggestions = [
          {
            category: 'dietary',
            text: `Find ${searchQuery} dishes suitable for your diet preferences`,
            subtext: 'Customized for your dietary needs'
          },
          {
            category: 'popular',
            text: `Trending ${searchQuery} dishes near you`,
            subtext: 'Based on local popularity'
          },
          {
            category: 'premium',
            text: `Premium ${searchQuery} specialties`,
            subtext: 'Handpicked by our chefs'
          },
          {
            category: 'healthy',
            text: `Healthy ${searchQuery} alternatives`,
            subtext: 'Under 500 calories'
          }
        ];
        setSearchSuggestions(suggestions);
        simulateAiTyping(`Finding the best ${searchQuery} options for you...`);
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setSearchSuggestions([]);
    }
  }, [searchQuery, isSearchFocused, simulateAiTyping]);

  // Voice search with feedback
  const handleVoiceSearch = () => {
    setIsListening(true);
    simulateAiTyping('Listening to your request...');
    setTimeout(() => {
      setIsListening(false);
      setSearchQuery('Voice search demo');
      simulateAiTyping('I heard: Voice search demo');
    }, 2000);
  };

  return (
    <div className="relative min-h-[90vh] overflow-hidden">
      {/* Enhanced Animated Gradient Background */}
      <motion.div 
        className="absolute inset-0 bg-gradient-to-br from-green-500 via-emerald-500 to-teal-600"
        animate={{
          background: [
            'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
            'linear-gradient(120deg, #4facfe 0%, #00f2fe 100%)',
            'linear-gradient(120deg, #43e97b 0%, #38f9d7 100%)',
            'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)'
          ]
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: 'reverse'
        }}
      />

      {/* Animated Pattern Overlay */}
      <motion.div 
        className="absolute inset-0"
        style={{
          backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.15) 1px, transparent 0)`,
          backgroundSize: '32px 32px'
        }}
        animate={{
          scale: [1, 1.1, 1],
          opacity: [0.1, 0.15, 0.1]
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: 'reverse'
        }}
      />

      {/* Main Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 md:py-32">
        <motion.div
          variants={fadeInUpVariants}
          initial="hidden"
          animate="visible"
          className="text-center text-white max-w-4xl mx-auto"
        >
          {/* Hero Title Section */}
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            <motion.div
              className="absolute -top-16 left-1/2 transform -translate-x-1/2 w-64 h-64 bg-white opacity-10 rounded-full blur-3xl"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.1, 0.15, 0.1]
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                repeatType: 'reverse'
              }}
            />
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-extrabold mb-6 leading-tight">
              {/* Ab ghar jaisa khana,Ghar tak!{' '} */}
              SaiGreen 2.0 Launch on
              <motion.span 
                className="text-yellow-300 inline-block"
                animate={{
                  textShadow: [
                    '0 0 8px rgba(250,204,21,0.2)',
                    '0 0 16px rgba(250,204,21,0.4)',
                    '0 0 8px rgba(250,204,21,0.2)'
                  ]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity
                }}
              >
                 10th Nov 2024
              </motion.span>
            </h1>
            <p className="text-xl md:text-2xl mb-12 text-green-50">
              Experience the magic of AI-powered food discovery with India's smartest cloud kitchen service.
            </p>
          </motion.div>

          {/* Enhanced Search Bar */}
          <div className="relative max-w-2xl mx-auto mb-12 z-10">
            <motion.div
              initial={false}
              animate={{ 
                height: isSearchFocused ? 'auto' : '64px',
                borderRadius: isSearchFocused ? '24px' : '9999px'
              }}
              className="bg-white/95 backdrop-blur-lg shadow-2xl overflow-hidden"
            >
              {/* Search Input Area */}
              <div className="flex items-center p-2">
                <motion.div 
                  className="flex items-center px-4 border-r border-gray-200"
                  whileHover={{ scale: 1.02 }}
                >
                  <FaMapMarkerAlt className="text-green-500 mr-2" />
                  <select className="bg-transparent text-gray-700 focus:outline-none font-medium">
                    <option>Bokaro Steel City</option>
                    <option>Chas</option>
                  </select>
                </motion.div>
                <div className="flex-1 flex items-center px-4 relative">
                  <input
                    type="text"
                    placeholder="Search dishes or ask me anything about food..."
                    className="w-full py-2 text-gray-700 focus:outline-none"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => setIsSearchFocused(true)}
                  />
                  {aiTypingText && (
                    <motion.div 
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="absolute -top-8 left-0 right-0 text-sm text-white bg-gray-800/80 
                        rounded-full py-1 px-4 flex items-center space-x-2"
                    >
                      <FaRobot className="text-green-400" />
                      <span>{aiTypingText}</span>
                    </motion.div>
                  )}
                  {searchQuery && (
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      onClick={() => setSearchQuery('')}
                      className="p-2 text-gray-400 hover:text-gray-600"
                    >
                      <FaTimes />
                    </motion.button>
                  )}
                </div>
                <div className="flex items-center space-x-2 px-2">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={handleVoiceSearch}
                    className={`p-2 rounded-full transition-colors ${
                      isListening ? 'bg-red-50 text-red-500 animate-pulse' : 'text-gray-400 hover:text-gray-600'
                    }`}
                  >
                    <FaMicrophone />
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-gradient-to-r from-green-500 to-emerald-600 text-white px-6 py-2 
                      rounded-full hover:shadow-lg transition-all duration-300"
                    onClick={() => handleSearch(searchQuery)}
                  >
                    <FaSearch />
                  </motion.button>
                </div>
              </div>

              {/* Enhanced Search Suggestions */}
              <AnimatePresence>
                {isSearchFocused && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="border-t border-gray-100"
                  >
                    {isLoading ? (
                      <div className="p-6 text-center text-gray-500">
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                          className="mx-auto mb-3"
                        >
                          <FaSpinner className="text-2xl text-green-500" />
                        </motion.div>
                        <p>AI is crafting personalized suggestions...</p>
                      </div>
                    ) : (
                      <div className="p-4">
                        {searchQuery && searchSuggestions.length > 0 ? (
                          <div className="space-y-3">
                            <div className="flex items-center space-x-2 px-2">
                              <FaRegLightbulb className="text-yellow-500" />
                              <p className="text-sm text-gray-600">
                                AI-Powered Suggestions for "{searchQuery}"
                              </p>
                            </div>
                            {searchSuggestions.map((suggestion, index) => (
                              <motion.button
                                key={index}
                                whileHover={{ x: 4, backgroundColor: 'rgba(243, 244, 246, 0.8)' }}
                                className="w-full flex items-center p-3 hover:bg-gray-50 rounded-xl 
                                  text-left transition-all group"
                                onClick={() => handleSearch(suggestion.text)}
                              >
                                {aiSuggestionCategories[suggestion.category].icon && (
                                  <div className={`flex-shrink-0 ${
                                    aiSuggestionCategories[suggestion.category].color
                                  }`}>
                                    {/* <aiSuggestionCategories[suggestion.category].icon size={20} /> */}
                                  </div>
                                )}
                                <div className="ml-3 flex-1">
                                  <p className="text-gray-700 font-medium">{suggestion.text}</p>
                                  <p className="text-sm text-gray-500">{suggestion.subtext}</p>
                                </div>
                                <FaArrowRight className="opacity-0 group-hover:opacity-100 
                                  text-gray-400 transition-opacity" />
                              </motion.button>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p className="text-xs text-gray-500 px-2 mb-3">Popular Searches</p>
                            <div className="flex flex-wrap gap-2">
                              {recentSearches.map((search, index) => (
                                <motion.button
                                  key={index}
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                  className="flex items-center space-x-2 px-4 py-2 bg-gray-100/80 
                                    backdrop-blur-sm rounded-full text-sm text-gray-700 
                                    hover:bg-gray-200/80 transition-all shadow-sm hover:shadow group"
                                  onClick={() => handleSearch(search)}
                                >
                                  <FaHistory className="text-gray-400 group-hover:text-green-500 
                                    transition-colors" />
                                  <span>{search}</span>
                                </motion.button>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>

          {/* Enhanced Stats Section */}
          <motion.div
            variants={fadeInUpVariants}
            className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 max-w-4xl mx-auto"
          >
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * index }}
                whileHover={{ y: -5, transition: { duration: 0.2 } }}
                className="relative group"
              >
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r opacity-0 group-hover:opacity-100 
                    blur-xl transition-opacity duration-500"
                  style={{
                    background: `linear-gradient(to right, ${stat.color})`,
                    opacity: 0.3
                  }}
                />
                <div className="bg-white/10 backdrop-blur-lg rounded-xl p-4 transform 
                  transition-all duration-300 group-hover:bg-white/20 relative z-10">
                  <motion.div
                    initial={{ scale: 1 }}
                    animate={{ scale: [1, 1.2, 1] }}
                    transition={{ 
                      duration: 0.5, 
                      times: [0, 0.5, 1], 
                      delay: 0.5 + index * 0.2 
                    }}
                    className="mb-2"
                  >
                    <stat.icon className="text-3xl mx-auto text-white/90 
                      group-hover:text-white transition-colors" />
                  </motion.div>
                  <motion.div
                    className="text-2xl font-bold mb-1"
                    animate={{ scale: [1, 1.05, 1] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    {stat.value}
                  </motion.div>
                  <div className="text-sm text-green-50">{stat.label}</div>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Enhanced CTA Section */}
          <motion.div
            variants={fadeInUpVariants}
            className="mt-12 space-y-6"
          >
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <motion.button
                whileHover={{ scale: 1.05, boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1)" }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-white text-green-600 rounded-full font-semibold text-lg 
                  shadow-xl hover:shadow-2xl transition-all duration-300 w-full sm:w-auto 
                  relative group overflow-hidden"
                onClick={() => navigate('/kitchens')}
              >
                <motion.span
                  className="absolute inset-0 bg-gradient-to-r from-green-100 to-emerald-100 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                />
                <span className="relative flex items-center justify-center space-x-2">
                  <FaUtensils className="text-green-500" />
                  <span>Explore Our Kitchens</span>
                </span>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05, boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1)" }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-gradient-to-r from-yellow-400 to-orange-400 
                  text-gray-900 rounded-full font-semibold text-lg shadow-xl 
                  hover:shadow-2xl transition-all duration-300 w-full sm:w-auto 
                  relative overflow-hidden group"
                onClick={() => navigate('/subscription')}
              >
                <motion.span
                  className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-orange-300 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                />
                <span className="relative flex items-center justify-center space-x-2">
                  <FaClock className="text-gray-800" />
                  <span>View Meal Plans</span>
                </span>
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      </div>

      {/* AI Assistant Floating Button with Enhanced Animation */}
      <motion.button
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        whileHover={{ scale: 1.1, boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.2)" }}
        whileTap={{ scale: 0.9 }}
        className="fixed bottom-6 right-6 z-20 p-4 bg-gradient-to-r from-green-500 
          to-emerald-600 rounded-full text-white shadow-lg hover:shadow-xl 
          transition-all duration-300 group"
        onClick={() => {/* AI Assistant logic */}}
      >
        <div className="relative">
          <FaRobot size={24} className="group-hover:rotate-12 transition-transform duration-300" />
          <motion.div
            className="absolute -top-1 -right-1 w-3 h-3 bg-yellow-400 rounded-full"
            animate={{
              scale: [1, 1.2, 1],
              boxShadow: [
                "0 0 0 0 rgba(250, 204, 21, 0.4)",
                "0 0 0 10px rgba(250, 204, 21, 0)",
                "0 0 0 0 rgba(250, 204, 21, 0)"
              ]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "loop"
            }}
          />
        </div>
      </motion.button>
    </div>
  );
};

export default Hero;
