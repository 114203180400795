import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaQuoteLeft, FaQuoteRight, FaStar, FaChevronLeft, 
  FaChevronRight, FaRegClock, FaUtensils, FaHeart
} from 'react-icons/fa';

// Enhanced testimonials data
const testimonials = [
  { 
    id: 1,
    name: 'Rahul Kumar', 
    role: 'Working Professional',
    company: 'Tech Solutions Ltd',
    location: 'Sector 4, Bokaro',
    quote: 'Sai Green\'s tiffin service has been a blessing. The food quality and taste are just like home-cooked meals. The portions are generous, and the delivery is always on time.',
    rating: 5,
    image: '/api/placeholder/64/64',
    tags: ['Premium Plan', 'Daily Lunch'],
    featuredDish: 'Dal Makhani Thali',
    orderingSince: '6 months'
  },
  { 
    id: 2,
    name: 'Priya Singh', 
    role: 'Student',
    company: 'IIT Dhanbad',
    location: 'City Center, Bokaro',
    quote: 'Affordable and delicious meals delivered right on time. The weekly menu variety keeps things interesting! As a student, the basic plan fits perfectly within my budget while ensuring I eat healthy.',
    rating: 5,
    image: '/api/placeholder/64/64',
    tags: ['Basic Plan', 'Monthly Subscription'],
    featuredDish: 'Veg Special Thali',
    orderingSince: '3 months'
  },
  { 
    id: 3,
    name: 'Amit Verma', 
    role: 'Business Owner',
    company: 'Verma Enterprises',
    location: 'Sector 1, Bokaro',
    quote: 'The Premium plan is perfect for my family. Fresh, healthy food for all three meals. What stands out is their consistency in maintaining quality and their willingness to accommodate special requests.',
    rating: 5,
    image: '/api/placeholder/64/64',
    tags: ['Family Pack', 'Premium Plan'],
    featuredDish: 'North Indian Thali',
    orderingSince: '1 year'
  },
  { 
    id: 4,
    name: 'Neha Sharma',
    role: 'Teacher',
    company: 'Delhi Public School',
    location: 'Sector 3, Bokaro',
    quote: 'The breakfast service is exceptional! I love how they blend traditional Indian breakfast items with modern healthy options. Their subscription model is very flexible and convenient.',
    rating: 5,
    image: '/api/placeholder/64/64',
    tags: ['Breakfast Plan', 'Healthy Options'],
    featuredDish: 'South Indian Breakfast',
    orderingSince: '4 months'
  },
  { 
    id: 5,
    name: 'Rajesh Patel',
    role: 'Doctor',
    company: 'City Hospital',
    location: 'Sector 2, Bokaro',
    quote: 'As a healthcare professional with irregular hours, their 24/7 delivery option is a lifesaver. The food is nutritious and perfectly balanced. Excellent service overall!',
    rating: 5,
    image: '/api/placeholder/64/64',
    tags: ['24/7 Delivery', 'Custom Plan'],
    featuredDish: 'Diet Special Thali',
    orderingSince: '8 months'
  }
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  // Auto-play functionality
  useEffect(() => {
    if (!isAutoPlaying) return;
    
    const timer = setInterval(() => {
      setDirection(1);
      setCurrentIndex((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [isAutoPlaying]);

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection) => {
    setDirection(newDirection);
    setCurrentIndex((prev) => (prev + newDirection + testimonials.length) % testimonials.length);
  };

  return (
    <div className="relative bg-gradient-to-b from-gray-50 to-white py-24 overflow-hidden">
      {/* Background Pattern */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '30px 30px'
        }}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-6"
          >
            What Our Customers Say
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl text-gray-600 max-w-3xl mx-auto"
          >
            Join thousands of satisfied customers who trust us with their daily meals
          </motion.p>
        </div>

        {/* Testimonials Slider */}
        <div className="relative h-[500px] max-w-4xl mx-auto">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentIndex}
              custom={direction}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
              className="absolute w-full"
            >
              <div className="bg-white rounded-2xl shadow-xl p-8 mx-4">
                <div className="flex flex-col md:flex-row gap-8">
                  {/* Customer Info */}
                  <div className="md:w-1/3">
                    <div className="relative">
                      <div className="w-16 h-16 rounded-full overflow-hidden mb-4">
                        <img 
                          src={testimonials[currentIndex].image} 
                          alt={testimonials[currentIndex].name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ delay: 0.3 }}
                        className="absolute -top-2 -right-2 bg-green-500 text-white p-2 rounded-full"
                      >
                        <FaQuoteLeft size={12} />
                      </motion.div>
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 mb-1">
                      {testimonials[currentIndex].name}
                    </h3>
                    <p className="text-green-600 font-medium mb-2">
                      {testimonials[currentIndex].role}
                    </p>
                    <p className="text-sm text-gray-500 mb-4">
                      {testimonials[currentIndex].company}
                    </p>
                    <div className="flex items-center text-yellow-400 mb-4">
                      {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                        <FaStar key={i} />
                      ))}
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center text-sm text-gray-600">
                        <FaRegClock className="mr-2" />
                        <span>Customer for {testimonials[currentIndex].orderingSince}</span>
                      </div>
                      <div className="flex items-center text-sm text-gray-600">
                        <FaUtensils className="mr-2" />
                        <span>Favorite: {testimonials[currentIndex].featuredDish}</span>
                      </div>
                    </div>
                  </div>

                  {/* Testimonial Content */}
                  <div className="md:w-2/3 relative">
                    <FaQuoteLeft className="absolute top-0 left-0 text-green-100 text-4xl" />
                    <blockquote className="text-gray-700 text-lg leading-relaxed mt-8 mb-6 relative z-10">
                      "{testimonials[currentIndex].quote}"
                    </blockquote>
                    <div className="flex flex-wrap gap-2 mt-4">
                      {testimonials[currentIndex].tags.map((tag, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-green-100 text-green-600 rounded-full text-sm font-medium"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Navigation Buttons */}
          <div className="absolute -left-4 -right-4 top-1/2 -mt-4 flex justify-between items-center">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => paginate(-1)}
              className="p-3 rounded-full bg-white shadow-lg text-gray-800 hover:text-green-600 
                transition-colors focus:outline-none"
            >
              <FaChevronLeft size={20} />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => paginate(1)}
              className="p-3 rounded-full bg-white shadow-lg text-gray-800 hover:text-green-600 
                transition-colors focus:outline-none"
            >
              <FaChevronRight size={20} />
            </motion.button>
          </div>

          {/* Pagination Dots */}
          <div className="absolute bottom-0 left-0 right-0 flex justify-center space-x-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  setDirection(index > currentIndex ? 1 : -1);
                  setCurrentIndex(index);
                }}
                className={`w-2 h-2 rounded-full transition-all duration-300 ${
                  index === currentIndex 
                    ? 'w-8 bg-green-500' 
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;