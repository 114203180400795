import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaAngleDown, FaRegLightbulb, FaRegClock, 
  FaShieldAlt, FaMapMarkedAlt, FaQuestionCircle
} from 'react-icons/fa';

const faqs = [
  { 
    question: 'What are your delivery areas?',
    answer: 'We currently deliver within a 5km radius in Bokaro Steel City with free delivery. For locations beyond 5km, additional delivery charges may apply.',
    icon: FaMapMarkedAlt,
    color: 'from-blue-500 to-indigo-600'
  },
  { 
    question: 'What are your working hours?',
    answer: 'We operate from 6 AM to 10 PM daily. Breakfast delivery starts early morning, lunch around noon, and dinner in the evening.',
    icon: FaRegClock,
    color: 'from-green-500 to-emerald-600'
  },
  { 
    question: 'How can I subscribe to your tiffin service?',
    answer: 'You can choose from our Basic, Standard, Premium, or Nashta plans. Monthly charges are to be paid in advance. You\'ll need to provide two tiffin containers.',
    icon: FaRegLightbulb,
    color: 'from-yellow-500 to-amber-600'
  },
  { 
    question: 'What is your cancellation policy?',
    answer: 'For cancellation of tiffin service, information should be given 1 day before. One-time tiffin cannot be cancelled.',
    icon: FaShieldAlt,
    color: 'from-red-500 to-rose-600'
  }
];

const FAQAccordion = ({ faq, isOpen, onClick, index }) => (
  <motion.div
    layout
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: index * 0.1 }}
    className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
  >
    <button
      onClick={onClick}
      className="w-full text-left p-6 focus:outline-none group"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <motion.div 
            whileHover={{ scale: 1.1 }}
            className={`p-3 rounded-xl bg-gradient-to-r ${faq.color} 
              text-white shadow-md group-hover:shadow-lg transition-shadow`}
          >
            <faq.icon size={20} />
          </motion.div>
          <h3 className="font-bold text-lg text-gray-800 group-hover:text-gray-900">
            {faq.question}
          </h3>
        </div>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3, type: "spring" }}
          className={`text-gray-400 group-hover:text-gray-600 
            transition-colors ${isOpen ? 'text-green-500' : ''}`}
        >
          <FaAngleDown size={24} />
        </motion.div>
      </div>
    </button>
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="overflow-hidden"
        >
          <div className="p-6 pt-0 text-gray-600 border-t border-gray-100">
            <motion.p
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="leading-relaxed"
            >
              {faq.answer}
            </motion.p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
);

const FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);
  
  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-white to-gray-50 py-20">
      {/* Background Pattern */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 1 }}
        className="absolute inset-0 pointer-events-none"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '30px 30px'
        }}
      />

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-block p-3 bg-green-100 rounded-2xl mb-4"
          >
            <FaQuestionCircle className="text-3xl text-green-600" />
          </motion.div>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-extrabold text-gray-900 mb-4"
          >
            Frequently Asked Questions
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl text-gray-600 max-w-2xl mx-auto"
          >
            Everything you need to know about our service. Can't find what you're looking for? 
            <button className="text-green-600 hover:text-green-700 font-medium ml-1">
              Contact us
            </button>
          </motion.p>
        </div>

        <motion.div 
          layout 
          className="space-y-4"
        >
          {faqs.map((faq, index) => (
            <FAQAccordion
              key={index}
              faq={faq}
              index={index}
              isOpen={openFAQ === index}
              onClick={() => setOpenFAQ(openFAQ === index ? null : index)}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default FAQ;