import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaUtensils, FaCalendarCheck, FaClock, FaTruck, 
  FaMobileAlt, FaLeaf, FaHeart, FaHandshake,
  FaCrown, FaUserClock, FaMoneyBillWave, FaArrowRight,
  FaCheckCircle, FaPhoneAlt, FaInfinity
} from 'react-icons/fa';

const HowItWorks = () => {
  const [activeTab, setActiveTab] = useState('quick');
  const [hoveredStep, setHoveredStep] = useState(null);

  const serviceTypes = {
    quick: {
      title: "Quick Order",
      tagline: "Perfect for instant cravings",
      icon: FaUtensils,
      color: "from-purple-500 to-indigo-600",
      benefits: [
        { 
          icon: FaClock,
          title: "Express Delivery",
          description: "Get your food delivered within 45 minutes"
        },
        { 
          icon: FaMoneyBillWave,
          title: "No Minimum Order",
          description: "Order as little or as much as you want"
        },
        { 
          icon: FaHeart,
          title: "Wide Selection",
          description: "Choose from 100+ dishes across cuisines"
        }
      ]
    },
    subscription: {
      title: "Subscription",
      tagline: "Smart choice for daily meals",
      icon: FaCrown,
      color: "from-green-500 to-emerald-600",
      benefits: [
        { 
          icon: FaInfinity,
          title: "Huge Savings",
          description: "Save up to 20% on every meal"
        },
        { 
          icon: FaCalendarCheck,
          title: "Flexible Plans",
          description: "Weekly, monthly, or custom duration"
        },
        { 
          icon: FaCalendarCheck,
          title: "Menu Variety",
          description: "Rotating menu with 20+ options daily"
        }
      ]
    }
  };

  const processSteps = [
    {
      title: "Browse & Select",
      description: "Choose your preferred kitchen and meals",
      icon: FaUtensils,
      color: "from-blue-500 to-indigo-600",
      features: [
        "Multiple cuisines available",
        "View detailed menu descriptions",
        "Check nutritional information",
        "Read customer reviews"
      ],
      animation: {
        hover: { scale: 1.02, y: -5 },
        tap: { scale: 0.98 }
      }
    },
    {
      title: "Customize",
      description: "Personalize your order to your taste",
      icon: FaHeart,
      color: "from-pink-500 to-rose-600",
      features: [
        "Adjust spice levels",
        "Choose portion sizes",
        "Add special instructions",
        "Select meal combinations"
      ],
      animation: {
        hover: { scale: 1.02, rotate: 5 },
        tap: { scale: 0.98 }
      }
    },
    {
      title: "Schedule",
      description: "Pick your preferred delivery time",
      icon: FaClock,
      color: "from-yellow-500 to-amber-600",
      features: [
        "Multiple time slots",
        "Advance booking",
        "Recurring schedules",
        "Flexible rescheduling"
      ],
      animation: {
        hover: { scale: 1.02, rotate: -5 },
        tap: { scale: 0.98 }
      }
    },
    {
      title: "Track & Enjoy",
      description: "Monitor your delivery in real-time",
      icon: FaTruck,
      color: "from-green-500 to-emerald-600",
      features: [
        "Live delivery tracking",
        "Direct chat with delivery partner",
        "Contactless delivery option",
        "Easy feedback system"
      ],
      animation: {
        hover: { scale: 1.02, x: 5 },
        tap: { scale: 0.98 }
      }
    }
  ];

  return (
    <div className="relative bg-gray-50 py-24 overflow-hidden">
      {/* Enhanced Background Pattern */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0"
        style={{
          background: `
            linear-gradient(90deg, #000 1px, transparent 1px),
            linear-gradient(180deg, #000 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px'
        }}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Enhanced Section Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="inline-flex items-center justify-center p-3 bg-green-100 
              rounded-2xl text-green-600 mb-6"
          >
            <FaHandshake size={32} />
          </motion.div>
          <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 mb-6">
            How Sai Green Works
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Choose your preferred way of ordering and let us take care of your meals
          </p>
        </motion.div>

        {/* Service Type Selector */}
        <div className="flex justify-center mb-16">
          <div className="bg-white p-2 rounded-full shadow-lg inline-flex">
            {Object.entries(serviceTypes).map(([key, service]) => (
              <motion.button
                key={key}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveTab(key)}
                className={`flex items-center space-x-2 px-6 py-3 rounded-full 
                  transition-all duration-300 ${
                  activeTab === key
                    ? `bg-gradient-to-r ${service.color} text-white shadow-md`
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                <service.icon size={20} />
                <span className="font-medium">{service.title}</span>
              </motion.button>
            ))}
          </div>
        </div>

        {/* Service Benefits */}
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="grid md:grid-cols-3 gap-8 mb-20"
          >
            {serviceTypes[activeTab].benefits.map((benefit, index) => (
              <motion.div
                key={benefit.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1)" 
                }}
                className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl 
                  transition-all duration-300"
              >
                <div className={`w-12 h-12 rounded-xl bg-gradient-to-r 
                  ${serviceTypes[activeTab].color} text-white flex items-center 
                  justify-center shadow-md mb-4`}>
                  <benefit.icon size={24} />
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>

        {/* Enhanced Process Steps */}
        <div className="relative">
          {/* Animated Connection Line */}
          <motion.div 
            className="absolute top-1/2 left-0 right-0 h-1 bg-gradient-to-r 
              from-green-500 to-emerald-600 -mt-px hidden md:block"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          />

          <div className="grid md:grid-cols-4 gap-8">
            {processSteps.map((step, index) => (
              <motion.div
                key={step.title}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * index }}
                onHoverStart={() => setHoveredStep(index)}
                onHoverEnd={() => setHoveredStep(null)}
                whileHover={step.animation.hover}
                whileTap={step.animation.tap}
                className="relative"
              >
                {/* Step Number Bubble */}
                <motion.div
                  className={`absolute -top-4 -left-4 w-10 h-10 bg-gradient-to-r 
                    ${step.color} rounded-full shadow-lg flex items-center justify-center 
                    text-white font-bold z-10`}
                  animate={{
                    scale: hoveredStep === index ? 1.1 : 1,
                    rotate: hoveredStep === index ? 360 : 0
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {index + 1}
                </motion.div>

                <div className="bg-white rounded-2xl p-6 shadow-lg h-full 
                  transform transition-all duration-300">
                  <motion.div
                    className={`w-16 h-16 rounded-xl bg-gradient-to-r ${step.color} 
                      text-white flex items-center justify-center shadow-lg mb-6`}
                    animate={{
                      scale: hoveredStep === index ? 1.1 : 1,
                      rotate: hoveredStep === index ? 5 : 0
                    }}
                  >
                    <step.icon size={32} />
                  </motion.div>
                  
                  <h3 className="text-xl font-bold text-gray-900 mb-3">
                    {step.title}
                  </h3>
                  <p className="text-gray-600 mb-4">{step.description}</p>
                  
                  <ul className="space-y-2">
                    {step.features.map((feature, i) => (
                      <motion.li
                        key={i}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: 0.3 + (i * 0.1) }}
                        className="flex items-center text-gray-700"
                      >
                        <FaCheckCircle className="text-green-500 mr-2" size={14} />
                        {feature}
                      </motion.li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Enhanced CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
          className="text-center mt-20"
        >
          <div className="bg-white rounded-2xl p-8 shadow-xl">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              Ready to Get Started?
            </h3>
            <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
              Experience the convenience of our service and enjoy delicious meals 
              delivered to your doorstep
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-3 bg-gradient-to-r from-green-500 to-emerald-600 
                  text-white rounded-full font-medium shadow-lg hover:shadow-xl 
                  transition-shadow duration-300 flex items-center space-x-2"
              >
                <FaUtensils size={16} />
                <span>Order Now</span>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 
                  text-white rounded-full font-medium shadow-lg hover:shadow-xl 
                  transition-shadow duration-300 flex items-center space-x-2"
              >
                <FaCrown size={16} />
                <span>View Subscription Plans</span>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-3 bg-white text-gray-700 rounded-full font-medium 
                  shadow-lg hover:shadow-xl transition-shadow duration-300 
                  flex items-center space-x-2 border border-gray-200"
              >
                <FaPhoneAlt size={16} />
                <span>Contact Support</span>
              </motion.button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default HowItWorks;