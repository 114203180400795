import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaUser, FaShoppingBag, FaPhone, FaMapMarkerAlt, FaBars, FaTimes, 
  FaHeart, FaSearch, FaBell, FaHistory, FaCog, FaSignOutAlt, 
  FaUserCircle, FaRegClock, FaPercent, FaHeadset, FaUtensils,
  FaArrowRight, FaChevronDown, FaHome, FaInfoCircle, FaCheckCircle,
  FaShieldAlt, FaExclamationCircle, FaAngleRight, FaEnvelope,
  FaGift, FaMapMarked, FaStore,FaMinus,FaPlus
} from 'react-icons/fa';

// Animation variants
const fadeIn = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

const slideIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
};

const scaleIn = {
  initial: { opacity: 0, scale: 0.9 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.9 }
};

// Custom hook for handling clicks outside components
const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

// Custom hook for scroll position
const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', updatePosition);

    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

// NavItem Component
const NavItem = ({ path, label, icon: Icon, isActive }) => {
  const iconSize = 18;
  
  return (
    <Link 
      to={path}
      className={`flex items-center space-x-2 px-3 py-2 rounded-full text-sm transition-all
        ${isActive 
          ? 'text-green-600 bg-green-50 font-medium' 
          : 'text-gray-600 hover:text-green-600 hover:bg-green-50'
        }`}
    >
      {Icon && <Icon size={iconSize} className="flex-shrink-0" />}
      <span className="font-medium whitespace-nowrap">{label}</span>
      {isActive && (
        <motion.div
          layoutId="nav-indicator"
          className="absolute inset-0 bg-green-50 rounded-full -z-10"
          initial={false}
          transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
        />
      )}
    </Link>
  );
};

// Badge Component
const Badge = ({ count, bgColor = 'bg-green-500', size = 'small' }) => {
  if (!count) return null;

  const sizeClasses = {
    small: 'w-4 h-4 text-xs',
    medium: 'w-5 h-5 text-sm',
    large: 'w-6 h-6 text-base'
  };

  return (
    <span className={`absolute -top-1 -right-1 ${bgColor} text-white
      ${sizeClasses[size]} rounded-full flex items-center justify-center
      font-medium`}>
      {count > 99 ? '99+' : count}
    </span>
  );
};

// Button Components
const IconButton = ({ icon: Icon, onClick, badge, className = '', notification = false }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={`relative p-2 text-gray-600 hover:text-green-600 transition-colors ${className}`}
      onClick={onClick}
    >
      <Icon size={18} className="flex-shrink-0" />
      {badge && <Badge count={badge} bgColor={notification ? 'bg-red-500' : 'bg-green-500'} />}
    </motion.button>
  );
};

const ActionButton = ({ icon: Icon, label, onClick, className = '' }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`flex items-center space-x-2 bg-gradient-to-r from-green-500 to-emerald-600
        text-white px-4 py-2 rounded-full text-sm font-medium
        hover:from-green-600 hover:to-emerald-700 transition-all shadow-sm
        hover:shadow-md ${className}`}
    >
      {Icon && <Icon size={14} />}
      <span>{label}</span>
    </motion.button>
  );
};

// SearchBar Component
const SearchBar = ({ placeholder, onClick, className = '' }) => {
  return (
    <div className={`relative ${className}`}>
      <input
        type="text"
        placeholder={placeholder}
        className="w-full pl-10 pr-4 py-2 rounded-full bg-gray-50 border border-gray-200
          focus:outline-none focus:ring-2 focus:ring-green-500 text-sm
          transition-all duration-300 hover:bg-gray-100"
        onClick={onClick}
        readOnly
      />
      <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
    </div>
  );
};

// Logo Component
const Logo = () => {
  return (
    <Link to="/" className="flex items-center space-x-3">
      <motion.div
        whileHover={{ rotate: 180 }}
        transition={{ duration: 0.5 }}
        className="w-10 h-10 rounded-full bg-gradient-to-r from-green-500 to-emerald-500 
          flex items-center justify-center shadow-lg"
      >
        <span className="text-white font-bold text-lg">SG</span>
      </motion.div>
      <div className="hidden md:block">
        <h1 className="text-lg font-bold bg-gradient-to-r from-green-600 to-emerald-500 
          text-transparent bg-clip-text leading-tight">Sai Green</h1>
        <p className="text-xs text-gray-500">Cloud Kitchen</p>
      </div>
    </Link>
  );
};

// LocationBar Component
const LocationBar = () => {
  return (
    <button className="flex items-center space-x-2 text-sm hover:text-green-200 transition-colors">
      <FaMapMarkerAlt size={14} />
      <span className="font-medium">Bokaro Steel City</span>
      <FaChevronDown size={12} className="opacity-75" />
    </button>
  );
};
// Modal Components
const Modal = ({ isOpen, onClose, children, maxWidth = 'max-w-md' }) => {
  const modalRef = useRef();
  useClickOutside(modalRef, onClose);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4
            backdrop-blur-sm"
        >
          <motion.div
            ref={modalRef}
            variants={scaleIn}
            initial="initial"
            animate="animate"
            exit="exit"
            className={`${maxWidth} w-full bg-white rounded-xl shadow-2xl overflow-hidden`}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const LocationModal = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [recentLocations] = useState([
    { id: 1, name: 'Bokaro Steel City', area: 'City Center' },
    { id: 2, name: 'Chas', area: 'Main Market' },
    { id: 3, name: 'Sector 4', area: 'Shopping Complex' }
  ]);

  const handleLocationSelect = (location) => {
    setSelectedCity(location);
    setTimeout(() => {
      onClose();
      // Additional location change logic here
    }, 300);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Select Location</h2>
          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <FaTimes className="text-gray-500" />
          </motion.button>
        </div>

        <div className="relative mb-6">
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for your location..."
            className="w-full pl-10 pr-4 py-3 bg-gray-50 rounded-lg border border-gray-200
              focus:outline-none focus:ring-2 focus:ring-green-500 text-gray-700"
          />
        </div>

        <div className="space-y-4">
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">Recent Locations</h3>
            <div className="space-y-2">
              {recentLocations.map((location) => (
                <motion.button
                  key={location.id}
                  whileHover={{ x: 4 }}
                  onClick={() => handleLocationSelect(location)}
                  className="w-full flex items-center space-x-3 p-3 hover:bg-gray-50 rounded-lg
                    text-left transition-colors"
                >
                  <FaMapMarked className="text-green-500 flex-shrink-0" />
                  <div>
                    <p className="font-medium text-gray-800">{location.name}</p>
                    <p className="text-sm text-gray-500">{location.area}</p>
                  </div>
                  {selectedCity?.id === location.id && (
                    <FaCheckCircle className="text-green-500 ml-auto flex-shrink-0" />
                  )}
                </motion.button>
              ))}
            </div>
          </div>

          <div className="border-t pt-4">
            <h3 className="text-sm font-medium text-gray-500 mb-2">Use Current Location</h3>
            <button
              onClick={() => {/* Geolocation logic */}}
              className="w-full flex items-center justify-center space-x-2 py-3 bg-green-50
                text-green-600 rounded-lg hover:bg-green-100 transition-colors"
            >
              <FaMapMarkerAlt />
              <span>Detect my location</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const SearchModal = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recentSearches] = useState([
    'Butter Chicken', 'Biryani', 'Thali', 'South Indian'
  ]);
  const [popularCategories] = useState([
    { id: 1, name: 'North Indian', icon: FaUtensils },
    { id: 2, name: 'Chinese', icon: FaStore },
    { id: 3, name: 'South Indian', icon: FaUtensils },
    { id: 4, name: 'Fast Food', icon: FaStore }
  ]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setIsLoading(true);
    // Simulated search delay
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxWidth="max-w-2xl">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Search</h2>
          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={onClose}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <FaTimes className="text-gray-500" />
          </motion.button>
        </div>

        <div className="relative mb-6">
          <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search for dishes, cuisines, or restaurants..."
            className="w-full pl-12 pr-4 py-3 bg-gray-50 rounded-lg border border-gray-200
              focus:outline-none focus:ring-2 focus:ring-green-500 text-lg"
            autoFocus
          />
          {searchQuery && !isLoading && (
            <button
              onClick={() => handleSearch('')}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400
                hover:text-gray-600"
            >
              <FaTimes />
            </button>
          )}
        </div>

        {isLoading ? (
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="animate-pulse flex items-center space-x-4">
                <div className="w-12 h-12 bg-gray-200 rounded-lg"></div>
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                </div>
              </div>
            ))}
          </div>
        ) : searchQuery ? (
          <div className="space-y-2">
            {/* Search results would go here */}
            <div className="text-sm text-gray-500">No results found for "{searchQuery}"</div>
          </div>
        ) : (
          <div className="space-y-6">
            {/* Recent Searches */}
            <div>
              <h3 className="font-medium text-gray-900 mb-3">Recent Searches</h3>
              <div className="flex flex-wrap gap-2">
                {recentSearches.map((search) => (
                  <motion.button
                    key={search}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="px-4 py-2 bg-gray-100 rounded-full text-sm hover:bg-gray-200
                      text-gray-700 transition-colors"
                    onClick={() => handleSearch(search)}
                  >
                    {search}
                  </motion.button>
                ))}
              </div>
            </div>

            {/* Popular Categories */}
            <div>
              <h3 className="font-medium text-gray-900 mb-3">Popular Categories</h3>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                {popularCategories.map((category) => (
                  <motion.button
                    key={category.id}
                    whileHover={{ scale: 1.02 }}
                    className="p-4 bg-gray-50 rounded-lg text-left hover:bg-gray-100
                      transition-colors group"
                  >
                    <category.icon className="text-gray-400 group-hover:text-green-500
                      transition-colors mb-2" />
                    <span className="text-gray-700 font-medium">{category.name}</span>
                  </motion.button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
// Panel Components
const Panel = ({ children, position = 'right', width = 'w-96' }) => {
  return (
    <motion.div
      variants={slideIn}
      initial="initial"
      animate="animate"
      exit="exit"
      className={`absolute top-full ${position === 'right' ? 'right-0' : 'left-0'} 
        mt-2 ${width} bg-white rounded-xl shadow-xl py-2 z-50`}
    >
      {children}
    </motion.div>
  );
};

const CartPreviewPanel = ({ items, onClose }) => {
  const navigate = useNavigate();
  const total = items.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const handleCheckout = () => {
    onClose();
    navigate('/checkout');
  };

  return (
    <Panel width="w-96">
      <div className="px-4 py-2 border-b flex justify-between items-center">
        <h3 className="font-semibold text-lg">Your Cart</h3>
        <span className="text-sm text-gray-500">{items.length} items</span>
      </div>

      {items.length > 0 ? (
        <>
          <div className="max-h-[60vh] overflow-y-auto">
            {items.map((item) => (
              <motion.div
                key={item.id}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex items-center justify-between px-4 py-3 hover:bg-gray-50
                  border-b border-gray-100 last:border-0"
              >
                <div className="flex items-center space-x-4">
                  <div className="relative w-16 h-16 rounded-lg overflow-hidden bg-gray-100">
                    <img
                      src={item.image || 'placeholder.jpg'}
                      alt={item.name}
                      className="w-full h-full object-cover"
                    />
                    {item.type === 'veg' && (
                      <div className="absolute top-0 left-0 m-1 w-4 h-4 bg-green-500 rounded-full" />
                    )}
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900">{item.name}</h4>
                    <div className="flex items-center space-x-2 mt-1">
                      <span className="text-sm text-gray-500">Qty: {item.quantity}</span>
                      <span className="text-sm text-gray-400">•</span>
                      <span className="text-sm font-medium text-green-600">
                        ₹{(item.price * item.quantity).toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="p-1 hover:bg-gray-100 rounded"
                  >
                    <FaMinus size={12} className="text-gray-500" />
                  </motion.button>
                  <span className="font-medium w-8 text-center">{item.quantity}</span>
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="p-1 hover:bg-gray-100 rounded"
                  >
                    <FaPlus size={12} className="text-gray-500" />
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </div>

          <div className="px-4 py-4 border-t bg-gray-50">
            <div className="space-y-3">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Subtotal</span>
                <span className="font-medium">₹{total.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Delivery Fee</span>
                <span className="font-medium">₹40.00</span>
              </div>
              <div className="flex justify-between font-medium text-base pt-2 border-t">
                <span>Total</span>
                <span className="text-green-600">₹{(total + 40).toFixed(2)}</span>
              </div>
            </div>

            <div className="mt-4 space-y-2">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleCheckout}
                className="w-full py-3 bg-gradient-to-r from-green-500 to-emerald-600
                  text-white rounded-lg font-medium hover:shadow-lg transition-shadow"
              >
                Proceed to Checkout
              </motion.button>
              <button
                onClick={onClose}
                className="w-full py-2 text-gray-600 hover:text-gray-800 text-sm"
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="py-12 text-center">
          <FaShoppingBag className="mx-auto text-4xl text-gray-300 mb-4" />
          <p className="text-gray-500 mb-4">Your cart is empty</p>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onClose}
            className="px-6 py-2 bg-green-500 text-white rounded-full text-sm
              hover:bg-green-600 transition-colors"
          >
            Browse Menu
          </motion.button>
        </div>
      )}
    </Panel>
  );
};

const NotificationsPanel = ({ notifications, onClose }) => {
  const [readNotifications, setReadNotifications] = useState(new Set());

  const markAsRead = (id) => {
    setReadNotifications(prev => new Set([...prev, id]));
  };

  const markAllAsRead = () => {
    setReadNotifications(new Set(notifications.map(n => n.id)));
  };

  return (
    <Panel width="w-80">
      <div className="px-4 py-2 border-b flex justify-between items-center">
        <h3 className="font-semibold text-lg">Notifications</h3>
        <button
          onClick={markAllAsRead}
          className="text-sm text-green-600 hover:text-green-700"
        >
          Mark all as read
        </button>
      </div>

      <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <motion.div
              key={notification.id}
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={`px-4 py-3 cursor-pointer hover:bg-gray-50 border-b border-gray-100
                last:border-0 ${readNotifications.has(notification.id) ? 'opacity-75' : ''}`}
              onClick={() => markAsRead(notification.id)}
            >
              <div className="flex items-start space-x-3">
                <div className={`p-2 rounded-full flex-shrink-0 ${
                  notification.type === 'order'
                    ? 'bg-green-100 text-green-600'
                    : 'bg-yellow-100 text-yellow-600'
                }`}>
                  {notification.type === 'order' ? <FaShoppingBag /> : <FaPercent />}
                </div>
                <div>
                  <p className="text-sm text-gray-800">{notification.message}</p>
                  <span className="text-xs text-gray-500 mt-1 block">
                    {notification.time}
                  </span>
                </div>
                {!readNotifications.has(notification.id) && (
                  <div className="w-2 h-2 bg-green-500 rounded-full flex-shrink-0 mt-2" />
                )}
              </div>
            </motion.div>
          ))
        ) : (
          <div className="py-12 text-center">
            <FaBell className="mx-auto text-4xl text-gray-300 mb-4" />
            <p className="text-gray-500">No new notifications</p>
          </div>
        )}
      </div>
    </Panel>
  );
};

const UserMenuPanel = ({ user, onClose }) => {
  const navigate = useNavigate();

  const menuItems = [
    { icon: FaUserCircle, label: 'Profile', path: '/profile' },
    { icon: FaHistory, label: 'Order History', path: '/orders' },
    { icon: FaHeart, label: 'Favorites', path: '/favorites' },
    { icon: FaPercent, label: 'Offers & Promos', path: '/offers' },
    { icon: FaGift, label: 'Rewards', path: '/rewards' },
    { icon: FaHeadset, label: 'Support', path: '/support' },
    { icon: FaCog, label: 'Settings', path: '/settings' },
  ];

  const handleNavigation = (path) => {
    onClose();
    navigate(path);
  };

  return (
    <Panel width="w-72">
      <div className="px-4 py-3 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-12 h-12 rounded-full bg-gradient-to-r from-green-500 to-emerald-500
            flex items-center justify-center text-white text-xl font-bold">
            {user.name.charAt(0)}
          </div>
          <div>
            <h3 className="font-medium text-gray-900">{user.name}</h3>
            <p className="text-sm text-gray-500">{user.email}</p>
          </div>
        </div>
      </div>

      <div className="py-2">
        {menuItems.map((item) => (
          <motion.button
            key={item.label}
            whileHover={{ x: 4 }}
            onClick={() => handleNavigation(item.path)}
            className="w-full px-4 py-2 flex items-center space-x-3 text-gray-700
              hover:bg-gray-50 transition-colors"
          >
            <item.icon className="text-gray-400" />
            <span>{item.label}</span>
          </motion.button>
        ))}

        <div className="px-4 pt-2 mt-2 border-t">
          <motion.button
            whileHover={{ x: 4 }}
            onClick={() => handleNavigation('/logout')}
            className="w-full py-2 flex items-center space-x-3 text-red-600
              hover:bg-red-50 transition-colors rounded-lg"
          >
            <FaSignOutAlt />
            <span>Logout</span>
          </motion.button>
        </div>
      </div>
    </Panel>
  );
};
// Main Navbar Component
const Navbar = () => {
  // State management
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showCartPreview, setShowCartPreview] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [user] = useState({
    name: 'John Doe',
    email: 'john.doe@example.com',
    avatar: null
  });

  const location = useLocation();
  const navigate = useNavigate();
  const scrollPosition = useScrollPosition();
  const headerRef = useRef(null);
  const userMenuRef = useRef(null);
  const cartPreviewRef = useRef(null);
  const notificationsRef = useRef(null);

  // Close dropdowns when clicking outside
  useClickOutside(userMenuRef, () => setShowUserMenu(false));
  useClickOutside(cartPreviewRef, () => setShowCartPreview(false));
  useClickOutside(notificationsRef, () => setShowNotifications(false));

  // Update scroll state
  useEffect(() => {
    setIsScrolled(scrollPosition > 20);
  }, [scrollPosition]);

  // Mock data initialization
  useEffect(() => {
    // Initialize notifications
    setNotifications([
      {
        id: 1,
        type: 'order',
        message: 'Your order #1234 is out for delivery!',
        time: '2m ago'
      },
      {
        id: 2,
        type: 'promo',
        message: 'Special 50% off on all main courses!',
        time: '1h ago'
      }
    ]);

    // Initialize cart items
    setCartItems([
      {
        id: 1,
        name: 'Butter Chicken',
        price: 350,
        quantity: 2,
        type: 'non-veg',
        image: '/butter-chicken.jpg'
      },
      {
        id: 2,
        name: 'Naan',
        price: 40,
        quantity: 4,
        type: 'veg',
        image: '/naan.jpg'
      }
    ]);
  }, []);

  // Navigation items
  const navigationItems = [
    {
      path: '/',
      label: 'Home',
      icon: FaHome
    },
    {
      path: '/menu',
      label: 'Our Kitchens',
      icon: FaUtensils
    },
    // {
    //   path: '/subscription',
    //   label: 'Tiffin Orders',
    //   icon: FaRegClock
    // },
    {
      path: '/about',
      label: 'About',
      icon: FaInfoCircle
    }
  ];

  // Mobile Menu Component
  const MobileMenu = () => (
    <AnimatePresence>
      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className="lg:hidden fixed inset-x-0 top-[108px] bg-white border-t shadow-xl 
            overflow-hidden z-40"
        >
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -20 },
              show: {
                opacity: 1,
                y: 0,
                transition: {
                  staggerChildren: 0.05
                }
              }
            }}
            initial="hidden"
            animate="show"
            className="max-w-7xl mx-auto px-4 py-4 space-y-4"
          >
            {/* Mobile Search */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -20 },
                show: { opacity: 1, y: 0 }
              }}
              className="relative"
            >
              <SearchBar
                placeholder="Search dishes & cuisines..."
                onClick={() => {
                  setIsMenuOpen(false);
                  setShowSearchModal(true);
                }}
                className="w-full"
              />
            </motion.div>

            {/* Navigation Links */}
            <nav className="space-y-2">
              {navigationItems.map((item) => (
                <motion.div
                  key={item.path}
                  variants={{
                    hidden: { opacity: 0, x: -20 },
                    show: { opacity: 1, x: 0 }
                  }}
                >
                  <Link
                    to={item.path}
                    className={`flex items-center space-x-3 px-4 py-3 rounded-xl
                      ${location.pathname === item.path
                        ? 'bg-green-50 text-green-600'
                        : 'text-gray-700 hover:bg-gray-50'
                      } transition-colors`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <item.icon size={20} />
                    <span className="font-medium">{item.label}</span>
                  </Link>
                </motion.div>
              ))}
            </nav>

            {/* Quick Actions */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 }
              }}
              className="grid grid-cols-2 gap-3 pt-4 border-t"
            >
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setIsMenuOpen(false);
                  navigate('/profile');
                }}
                className="flex items-center justify-center space-x-2 px-6 py-3
                  bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-colors"
              >
                <FaUser size={18} />
                <span className="font-medium">Profile</span>
              </motion.button>
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setIsMenuOpen(false);
                  setShowCartPreview(true);
                }}
                className="flex items-center justify-center space-x-2 px-6 py-3
                  bg-green-500 text-white rounded-xl hover:bg-green-600 transition-colors"
              >
                <FaShoppingBag size={18} />
                <span className="font-medium">Cart ({cartItems.length})</span>
              </motion.button>
            </motion.div>

            {/* Additional Links */}
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 20 },
                show: { opacity: 1, y: 0 }
              }}
              className="space-y-2 pt-4 border-t"
            >
              <Link 
                to="/track-order"
                className="flex items-center space-x-3 px-4 py-3 text-gray-700 
                  hover:bg-gray-50 rounded-xl"
                onClick={() => setIsMenuOpen(false)}
              >
                <FaRegClock size={18} />
                <span>Track Order</span>
              </Link>
              <Link
                to="/offers"
                className="flex items-center space-x-3 px-4 py-3 text-gray-700 
                  hover:bg-gray-50 rounded-xl"
                onClick={() => setIsMenuOpen(false)}
              >
                <FaPercent size={18} />
                <span>Today's Offers</span>
              </Link>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  // Render main navbar
  return (
    <header ref={headerRef} className="fixed w-full top-0 z-50">
      {/* Top Bar */}
      <div className="bg-gradient-to-r from-green-600 to-emerald-600 text-white">
        <div className="max-w-7xl mx-auto px-4 py-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-6">
              <Link to="tel:+916204968836" className="hidden sm:flex items-center space-x-2 
                hover:text-green-200 transition-colors">
                <FaPhone className="text-green-200" size={14} />
                <span className="text-sm">+91 6204968836</span>
              </Link>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowLocationModal(true)}
                className="flex items-center space-x-2 hover:text-green-200 
                  transition-colors text-sm group"
              >
                <FaMapMarkerAlt className="text-green-200 group-hover:text-green-200" />
                <span>Bokaro Steel City</span>
                <motion.span
                  animate={{ rotate: showLocationModal ? 180 : 0 }}
                  className="text-xs ml-1"
                >
                  ▾
                </motion.span>
              </motion.button>
            </div>

            <div className="hidden md:flex items-center space-x-6">
              <Link to="/track-order" className="text-sm hover:text-green-200 
                transition-colors flex items-center space-x-2">
                <FaRegClock size={14} />
                <span>Track Order</span>
              </Link>
              <Link to="/offers" className="text-sm hover:text-green-200 
                transition-colors flex items-center space-x-2">
                <FaPercent size={14} />
                <span>Today's Offers</span>
              </Link>
              <a href="#download" className="text-sm hover:text-green-200 transition-colors">
                Download App
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Main Navigation Bar */}
      <div className={`${
        isScrolled 
          ? 'bg-white/90 backdrop-blur-lg shadow-lg' 
          : 'bg-white'
        } transition-all duration-300`}
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo and Nav Group */}
            <div className="flex items-center space-x-8">
              <Logo />
              
              {/* Desktop Navigation */}
              <nav className="hidden lg:flex items-center space-x-1">
                {navigationItems.map((item) => (
                  <NavItem 
                    key={item.path}
                    {...item}
                    isActive={location.pathname === item.path}
                  />
                ))}
              </nav>
            </div>

            {/* Search and Actions Group */}
            <div className="flex items-center space-x-4">
              {/* Search Bar - Desktop */}
              <div className="hidden md:block relative">
                <SearchBar
                  placeholder="Search dishes & cuisines..."
                  onClick={() => setShowSearchModal(true)}
                  className="w-64 lg:w-80"
                />
              </div>

              {/* Action Buttons */}
              <div className="flex items-center space-x-3">
                {/* Notifications */}
                <div className="relative" ref={notificationsRef}>
                  <IconButton
                    icon={FaBell}
                    onClick={() => setShowNotifications(!showNotifications)}
                    badge={notifications.length}
                    notification={true}
                    className="hidden sm:block"
                  />
                  <AnimatePresence>
                    {showNotifications && (
                      <NotificationsPanel 
                        notifications={notifications}
                        onClose={() => setShowNotifications(false)}
                      />
                    )}
                  </AnimatePresence>
                </div>

                {/* Favorites */}
                <IconButton
                  icon={FaHeart}
                  onClick={() => navigate('/favorites')}
                  badge={2}
                  className="hidden sm:block"
                />

                {/* Cart */}
                <div className="relative" ref={cartPreviewRef}>
                  <IconButton
                    icon={FaShoppingBag}
                    onClick={() => setShowCartPreview(!showCartPreview)}
                    badge={cartItems.length}
                  />
                  <AnimatePresence>
                    {showCartPreview && (
                      <CartPreviewPanel 
                        items={cartItems}
                        onClose={() => setShowCartPreview(false)}
                      />
                    )}
                  </AnimatePresence>
                </div>

                {/* User Menu */}
                <div className="relative hidden sm:block" ref={userMenuRef}>
                  <ActionButton
                    icon={FaUser}
                    label="Account"
                    onClick={() => setShowUserMenu(!showUserMenu)}
                  />
                  <AnimatePresence>
                    {showUserMenu && (
                      <UserMenuPanel 
                        user={user}
                        onClose={() => setShowUserMenu(false)}
                      />
                    )}
                  </AnimatePresence>
                </div>

                {/* Mobile Menu Toggle */}
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="lg:hidden p-2 rounded-lg text-gray-600 hover:bg-gray-100"
                >
                  {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu />

      {/* Modals */}
      <LocationModal 
        isOpen={showLocationModal}
        onClose={() => setShowLocationModal(false)}
      />
      <SearchModal 
        isOpen={showSearchModal}
        onClose={() => setShowSearchModal(false)}
      />
    </header>
  );
};

export default Navbar;