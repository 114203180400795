import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  FaFacebookF, FaInstagram, FaTwitter, FaWhatsapp,
  FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaHeart,
  FaUtensils, FaTruck, FaClock, FaShieldAlt, FaGift 
} from 'react-icons/fa';

const Footer = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const features = [
  
  ];

  return (
    <footer className="relative bg-gradient-to-b from-white to-gray-50 pt-16 pb-8 overflow-hidden">
      {/* Background Pattern */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.05 }}
        transition={{ duration: 1 }}
        className="absolute inset-0 pointer-events-none"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '30px 30px'
        }}
      />

      {/* Features Bar */}
      {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-2 md:grid-cols-4 gap-8"
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="flex flex-col items-center text-center"
            >
              <div className="w-16 h-16 rounded-full bg-gradient-to-r from-green-500 to-emerald-600 
                flex items-center justify-center text-white mb-4 shadow-lg">
                <feature.icon size={24} />
              </div>
              <span className="text-gray-600 font-medium">{feature.text}</span>
            </motion.div>
          ))}
        </motion.div>
      </div> */}

      {/* Main Footer Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12"
        >
          {/* Brand Section */}
          <motion.div variants={itemVariants} className="space-y-6">
            <Link to="/" className="flex items-center">
              <div className="w-12 h-12 rounded-xl bg-gradient-to-r from-green-500 to-emerald-600 
                flex items-center justify-center text-white shadow-lg transform transition-transform
                hover:scale-110">
                <FaUtensils size={24} />
              </div>
              <div className="ml-3">
                <h1 className="text-xl font-bold bg-gradient-to-r from-green-600 to-emerald-500 
                  text-transparent bg-clip-text">Sai Green</h1>
                <p className="text-sm text-gray-500">Cloud Kitchen</p>
              </div>
            </Link>
            <p className="text-gray-500">
              Delicious and nutritious meals delivered to your doorstep. 
              Serving Bokaro with love since 2014.
            </p>
            <div className="flex space-x-4">
              {[
                { icon: FaPhoneAlt, info: "+91 6204968836" },
                { icon: FaMapMarkerAlt, info: "Bokaro Steel City" },
                { icon: FaEnvelope, info: "contact@saigreen.in" }
              ].map((contact, index) => (
                <motion.a
                  key={index}
                  href="#"
                  whileHover={{ y: -3 }}
                  className="p-3 bg-white rounded-full shadow-md hover:shadow-lg transition-shadow text-gray-600
                    hover:text-green-500"
                >
                  <contact.icon size={16} />
                  <span className="sr-only">{contact.info}</span>
                </motion.a>
              ))}
            </div>
          </motion.div>

          {/* Quick Links Sections */}
          {[
            {
              title: "Services",
              links: [
                { text: "Daily Meals", path: "/menu" },
                { text: "Corporate Catering", path: "/corporate" },
                { text: "Elderly Care Meals", path: "/elderly-care" },
                { text: "Subscription Plans", path: "/subscription" }
              ]
            },
            {
              title: "Company",
              links: [
                { text: "About Us", path: "/about" },
                { text: "Blog", path: "/blog" },
                { text: "Careers", path: "/careers" },
                { text: "Contact", path: "/contact" }
              ]
            },
            {
              title: "Legal",
              links: [
                { text: "Privacy Policy", path: "/privacy" },
                { text: "Terms of Service", path: "/terms" },
                { text: "Cookie Policy", path: "/cookies" },
                { text: "Shipping Policy", path: "/shipping" }
              ]
            }
          ].map((section, sectionIndex) => (
            <motion.div key={section.title} variants={itemVariants} className="space-y-6">
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                {section.title}
              </h3>
              <ul className="space-y-4">
                {section.links.map((link, linkIndex) => (
                  <motion.li key={linkIndex}>
                    <Link
                      to={link.path}
                      className="text-gray-500 hover:text-green-600 transition-colors flex items-center
                        group"
                    >
                      <motion.span
                        className="w-1 h-1 bg-green-500 rounded-full mr-2 opacity-0 group-hover:opacity-100"
                        animate={{ scale: [1, 1.5, 1] }}
                        transition={{ duration: 1, repeat: Infinity }}
                      />
                      {link.text}
                    </Link>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </motion.div>

        {/* Social Links & Copyright */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="mt-16 pt-8 border-t border-gray-200"
        >
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex space-x-4">
              {[FaFacebookF, FaInstagram, FaTwitter, FaWhatsapp].map((Icon, index) => (
                <motion.a
                  key={index}
                  href="#"
                  whileHover={{ y: -3, scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="p-3 bg-white rounded-full shadow-md hover:shadow-lg text-gray-600
                    hover:text-green-500 transition-colors"
                >
                  <Icon size={18} />
                </motion.a>
              ))}
            </div>
            <motion.p
              variants={itemVariants}
              className="text-gray-500 flex items-center text-sm"
            >
              © 2024 Sai Green. Made with <FaHeart className="text-red-500 mx-1" /> in Bokaro
            </motion.p>
          </div>
        </motion.div>
      </div>

      {/* Newsletter Section */}
      {/* <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16"
      >
        <div className="bg-gradient-to-r from-green-500 to-emerald-600 rounded-2xl p-8 md:p-12
          shadow-xl relative overflow-hidden">
          <div className="absolute inset-0 opacity-10">
            <div className="absolute inset-0" style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
              backgroundSize: '30px 30px'
            }} />
          </div>
          <div className="relative z-10">
            <div className="text-center max-w-2xl mx-auto">
              <h3 className="text-2xl md:text-3xl font-bold text-white mb-4">
                Stay Updated with Our Latest Offers
              </h3>
              <p className="text-green-50 mb-8">
                Subscribe to our newsletter and get exclusive deals straight to your inbox!
              </p>
              <div className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-1 px-6 py-3 rounded-full focus:outline-none focus:ring-2 
                    focus:ring-green-300 shadow-inner"
                />
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="px-8 py-3 bg-white text-green-600 rounded-full font-medium 
                    shadow-lg hover:shadow-xl transition-shadow"
                >
                  Subscribe
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </motion.div> */}
    </footer>
  );
};

export default Footer;