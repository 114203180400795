import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';
import { 
  FaUtensils, FaClock, FaTruck, FaMobileAlt, FaLeaf, 
  FaHeart, FaSearch, FaTimes, FaFilter, FaPlus, FaMinus,
  FaInfoCircle, FaCrown, FaFire, FaArrowRight, FaSortAmountDown,
  FaStar, FaBolt, FaRegClock
} from 'react-icons/fa';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { menuItems, filterOptions, cuisineColors } from './menuItems';
const Notification = ({ message, type, onClose }) => (
  <motion.div
    initial={{ opacity: 0, y: 0 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 0 }}
    className={`fixed bottom-4 right-4 px-6 py-3 rounded-xl shadow-lg 
      text-white z-50 ${type === 'success' ? 'bg-green-500' : 'bg-blue-500'}`}
  >
    <div className="flex items-center space-x-3">
      <span>{message}</span>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={onClose}
        className="hover:text-gray-200 transition-colors"
      >
        <FaTimes />
      </motion.button>
    </div>
  </motion.div>
);
const DishCard = ({ dish, isFavorite, onFavoriteToggle, onAddToCart }) => {
  const [quantity, setQuantity] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedSize, setSelectedSize] = useState('full');

  const handleQuantityChange = (delta) => {
    const newQuantity = Math.max(0, Math.min(10, quantity + delta));
    setQuantity(newQuantity);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: -5 }}
      className="bg-white rounded-2xl shadow-lg overflow-hidden relative group"
    >
      {/* Image Section */}
      <div className="relative h-48 overflow-hidden">
        <motion.img
          src={dish.image || `/images/${dish.category.toLowerCase().replace(/\s+/g, '-')}.jpg`}
          alt={dish.name}
          className="w-full h-full object-cover transition-transform duration-500 
            group-hover:scale-110"
          onError={(e) => {
            e.target.src = '/images/kitchen1.jpg';
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 
          to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="absolute bottom-4 left-4 right-4">
            <h3 className="text-white font-bold text-lg">{dish.name}</h3>
            <p className="text-gray-200 text-sm">{dish.category}</p>
          </div>
        </div>

        {/* Badges */}
        <div className="absolute top-4 left-4 flex flex-col gap-2">
          {dish.isNew && (
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              className="px-3 py-1 bg-gradient-to-r from-yellow-400 to-orange-500 
                rounded-full text-white text-sm font-medium shadow-lg 
                flex items-center gap-1"
            >
              <FaFire className="text-yellow-100" size={12} />
              <span>New</span>
            </motion.div>
          )}
          {dish.bestSeller && (
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.1 }}
              className="px-3 py-1 bg-gradient-to-r from-purple-500 to-pink-500 
                rounded-full text-white text-sm font-medium shadow-lg 
                flex items-center gap-1"
            >
              <FaCrown className="text-yellow-300" size={12} />
              <span>Best Seller</span>
            </motion.div>
          )}
        </div>

        {/* Action Buttons */}
        <div className="absolute top-4 right-4 flex flex-col gap-2">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={onFavoriteToggle}
            className="p-2 rounded-full bg-white/90 backdrop-blur-sm shadow-lg 
              hover:bg-white transition-colors"
          >
            <FaHeart 
              className={isFavorite ? 'text-red-500' : 'text-gray-400'} 
              size={16} 
            />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setShowDetails(true)}
            className="p-2 rounded-full bg-white/90 backdrop-blur-sm shadow-lg 
              hover:bg-white transition-colors"
          >
            <FaInfoCircle className="text-gray-600" size={16} />
          </motion.button>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-4">
        {/* Info Badges */}
        <div className="flex flex-wrap gap-2 mb-3">
          {dish.isVeg && (
            <span className="px-2 py-1 bg-green-50 text-green-600 rounded-full 
              text-xs font-medium flex items-center gap-1">
              <FaLeaf size={12} />
              <span>Pure Veg</span>
            </span>
          )}
          <span className="px-2 py-1 bg-blue-50 text-blue-600 rounded-full 
            text-xs font-medium flex items-center gap-1">
            <FaClock size={12} />
            <span>{dish.preparationTime} mins</span>
          </span>
        </div>

        {/* Price and Size Selection */}
        <div className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <div className="space-x-2">
              {Object.keys(dish.price).map(size => (
                <motion.button
                  key={size}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setSelectedSize(size)}
                  className={`px-3 py-1 rounded-full text-sm font-medium 
                    ${selectedSize === size
                      ? 'bg-green-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                >
                  {size.charAt(0).toUpperCase() + size.slice(1)}
                </motion.button>
              ))}
            </div>
            <div className="text-xl font-bold text-green-600">
              {dish.price[selectedSize]}
            </div>
          </div>
        </div>

        {/* Add to Cart Section */}
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={() => handleQuantityChange(-1)}
              disabled={quantity === 0}
              className={`w-8 h-8 rounded-full flex items-center justify-center
                ${quantity > 0
                  ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  : 'bg-gray-50 text-gray-400 cursor-not-allowed'
                }`}
            >
              <FaMinus size={12} />
            </motion.button>
            <span className="w-8 text-center font-medium">{quantity}</span>
            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={() => handleQuantityChange(1)}
              className="w-8 h-8 rounded-full bg-gray-100 text-gray-700 
                hover:bg-gray-200 flex items-center justify-center"
            >
              <FaPlus size={12} />
            </motion.button>
          </div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={quantity === 0}
            onClick={() => {
              if (quantity > 0) {
                onAddToCart();
                setQuantity(0);
              }
            }}
            className={`px-4 py-2 rounded-full font-medium transition-colors
              ${quantity > 0
                ? 'bg-green-500 text-white hover:bg-green-600'
                : 'bg-gray-100 text-gray-400'
              }`}
          >
            Add to Cart
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};
const FilterModal = ({ isOpen, onClose, filters, activeFilters, onFilterChange }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-white rounded-2xl p-6 max-w-md w-full"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-bold text-gray-900">Filters</h3>
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <FaTimes className="text-gray-500" />
            </motion.button>
          </div>

          <div className="space-y-6">
            {Object.entries(filters).map(([category, options]) => (
              <div key={category}>
                <h4 className="font-medium text-gray-700 mb-3">{category}</h4>
                <div className="flex flex-wrap gap-2">
                  {options.map(option => (
                    <motion.button
                      key={option}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => onFilterChange(category, option)}
                      className={`px-4 py-2 rounded-xl text-sm font-medium transition-all
                        ${activeFilters[category]?.includes(option)
                          ? 'bg-green-500 text-white shadow-md'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                    >
                      {option}
                    </motion.button>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8 flex justify-end space-x-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => onFilterChange(null, null, true)}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Clear All
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={onClose}
              className="px-6 py-2 bg-green-500 text-white rounded-xl font-medium
                hover:bg-green-600 transition-colors shadow-md hover:shadow-lg"
            >
              Apply Filters
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

const TrendingDishes = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [sortBy, setSortBy] = useState('popular');
  const [notification, setNotification] = useState(null);
  const [favorites, setFavorites] = useState(new Set());
  const searchDebounceRef = useRef(null);
  const navigate = useNavigate();

  // Get all dishes in a flat array
  const allDishes = menuItems.reduce((acc, category) => {
    return [...acc, ...category.items.map(item => ({
      ...item,
      category: category.category
    }))];
  }, []);

  // Categories for the filter tabs
  const categories = ['All', ...new Set(menuItems.map(item => item.category))];

  const handleAddToCart = useCallback((dish) => {
    setNotification({
      message: `${dish.name} added to cart!`,
      type: 'success'
    });
    setTimeout(() => setNotification(null), 3000);
  }, []);

  // Filter and sort dishes
  const processedDishes = allDishes
    .filter(dish => {
      if (selectedCategory !== 'All' && dish.category !== selectedCategory) return false;
      if (searchQuery) {
        const searchTerms = searchQuery.toLowerCase();
        return dish.name.toLowerCase().includes(searchTerms) ||
               dish.description.toLowerCase().includes(searchTerms) ||
               dish.ingredients.some(ing => ing.toLowerCase().includes(searchTerms));
      }
      return true;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'price':
          return parseFloat(a.price.full.replace('₹', '')) - parseFloat(b.price.full.replace('₹', ''));
        case 'rating':
          return b.rating - a.rating;
        case 'time':
          return a.preparationTime - b.preparationTime;
        default:
          return b.rating - a.rating;
      }
    });

  return (
    <div className="relative bg-gradient-to-b from-gray-50 to-white py-20 overflow-hidden">
      {/* Animated Background Pattern */}
      <motion.div
        className="absolute inset-0 pointer-events-none"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.05 }}
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '30px 30px'
        }}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Section Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="inline-block p-3 bg-gradient-to-r from-green-50 to-emerald-50 
              rounded-2xl shadow-sm mb-4"
          >
            <motion.div
              animate={{ 
                rotate: [0, 10, 0], 
                scale: [1, 1.1, 1] 
              }}
              transition={{ 
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse"
              }}
            >
              <FaUtensils className="text-4xl text-gradient from-green-500 to-emerald-600" />
            </motion.div>
          </motion.div>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold text-gray-900 mb-4"
          >
            Trending Dishes
            <motion.span 
              className="inline-block ml-3"
              animate={{ 
                rotate: [0, 15, 0],
                scale: [1, 1.2, 1]
              }}
              transition={{ 
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse"
              }}
            >
              <FaFire className="text-orange-500" />
            </motion.span>
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl text-gray-600 max-w-3xl mx-auto"
          >
            Discover our most popular and delicious dishes, crafted with love and served with care
          </motion.p>
        </motion.div>

        {/* Enhanced Search and Filters */}
        <div className="mb-12 space-y-6">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="text-gray-400" />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by dish name, ingredients, or category..."
                className="w-full pl-10 pr-4 py-3 rounded-xl border border-gray-200 focus:outline-none
                  focus:ring-2 focus:ring-green-500 focus:border-transparent shadow-sm"
              />
            </div>
            <div className="flex gap-3">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setShowFilters(true)}
                className="px-6 py-3 rounded-xl bg-white border border-gray-200 text-gray-700
                  hover:text-green-600 hover:border-green-500 shadow-sm flex items-center space-x-2"
              >
                <FaFilter />
                <span>Filters</span>
                {Object.keys(activeFilters).length > 0 && (
                  <span className="bg-green-500 text-white w-5 h-5 rounded-full flex items-center 
                    justify-center text-xs">
                    {Object.keys(activeFilters).length}
                  </span>
                )}
              </motion.button>

              <div className="relative group">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setSortBy(current => {
                    const options = ['popular', 'price', 'rating', 'time'];
                    const currentIndex = options.indexOf(current);
                    return options[(currentIndex + 1) % options.length];
                  })}
                  className="px-6 py-3 rounded-xl bg-white border border-gray-200 text-gray-700
                    hover:text-green-600 hover:border-green-500 shadow-sm flex items-center space-x-2"
                >
                  <FaSortAmountDown />
                  <span>Sort by {sortBy}</span>
                </motion.button>
                
                <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-xl shadow-xl 
                  opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none 
                  group-hover:pointer-events-auto z-10">
                  {[
                    { key: 'popular', icon: FaFire, label: 'Most Popular' },
                    { key: 'price', icon: FaBolt, label: 'Best Price' },
                    { key: 'rating', icon: FaStar, label: 'Top Rated' },
                    { key: 'time', icon: FaRegClock, label: 'Preparation Time' }
                  ].map(option => (
                    <button
                      key={option.key}
                      onClick={() => setSortBy(option.key)}
                      className={`w-full px-4 py-2 text-left flex items-center space-x-2
                        hover:bg-gray-50 ${sortBy === option.key ? 'text-green-600' : 'text-gray-700'}`}
                    >
                      <option.icon className="text-current" />
                      <span>{option.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Category Pills */}
          <div className="flex overflow-x-auto pb-4 hide-scrollbar">
            <div className="flex space-x-2">
              {categories.map(category => (
                <motion.button
                  key={category}
                  whileHover={{ scale: 1.05, y: -2 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-6 py-3 rounded-xl font-medium whitespace-nowrap 
                    transition-all duration-300 ${
                    selectedCategory === category
                      ? `bg-gradient-to-r ${
                          category === 'All' 
                            ? 'from-green-500 to-emerald-600' 
                            : cuisineColors[category] || 'from-gray-500 to-gray-600'
                        } text-white shadow-lg`
                        : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-green-600 border border-gray-200'
                      }`}
                    >
                      <span className="relative z-10">{category}</span>
                    </motion.button>
                  ))}
                </div>
              </div>
            </div>
    
            {/* Dishes Display */}
            {processedDishes.length > 0 ? (
              <div className="relative">
                {/* Featured Dishes Slider */}
                <Swiper
                  modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
                  effect="coverflow"
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  navigation
                  pagination={{ 
                    clickable: true,
                    dynamicBullets: true,
                  }}
                  breakpoints={{
                    640: { slidesPerView: 1 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                    1280: { slidesPerView: 4 },
                  }}
                  spaceBetween={30}
                  className="pb-12"
                >
                  {processedDishes.map((dish) => (
                    <SwiperSlide key={dish.id}>
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <DishCard
                          dish={dish}
                          isFavorite={favorites.has(dish.id)}
                          onFavoriteToggle={() => {
                            const newFavorites = new Set(favorites);
                            if (newFavorites.has(dish.id)) {
                              newFavorites.delete(dish.id);
                            } else {
                              newFavorites.add(dish.id);
                            }
                            setFavorites(newFavorites);
                            setNotification({
                              message: newFavorites.has(dish.id) 
                                ? `${dish.name} added to favorites!` 
                                : `${dish.name} removed from favorites!`,
                              type: newFavorites.has(dish.id) ? 'success' : 'info'
                            });
                          }}
                          onAddToCart={() => handleAddToCart(dish)}
                        />
                      </motion.div>
                    </SwiperSlide>
                  ))}
                </Swiper>
    
                {/* Navigation Arrows */}
                <div className="absolute top-1/2 -left-4 -right-4 flex justify-between items-center 
                  pointer-events-none z-10">
                  <button className="swiper-button-prev !static !mt-0 !w-10 !h-10 rounded-full bg-white
                    shadow-lg text-gray-800 hover:text-green-600 pointer-events-auto transition-colors
                    flex items-center justify-center after:!content-['']">
                    <FaArrowRight className="rotate-180" />
                  </button>
                  <button className="swiper-button-next !static !mt-0 !w-10 !h-10 rounded-full bg-white
                    shadow-lg text-gray-800 hover:text-green-600 pointer-events-auto transition-colors
                    flex items-center justify-center after:!content-['']">
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            ) : (
              // Empty State
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center py-12"
              >
                <motion.div
                  animate={{ 
                    scale: [1, 1.1, 1],
                    rotate: [0, 10, 0]
                  }}
                  transition={{ 
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }}
                  className="inline-block p-4 rounded-full bg-gray-100 mb-4"
                >
                  <FaUtensils className="text-4xl text-gray-400" />
                </motion.div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  No dishes found
                </h3>
                <p className="text-gray-600">
                  Try adjusting your filters or search for something else
                </p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setSearchQuery('');
                    setSelectedCategory('All');
                    setActiveFilters({});
                  }}
                  className="mt-6 px-6 py-3 bg-green-500 text-white rounded-xl font-medium
                    hover:bg-green-600 transition-colors shadow-lg"
                >
                  Reset Filters
                </motion.button>
              </motion.div>
            )}
    
            {/* View All Button */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="text-center mt-12"
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate('/menu')}
                className="inline-flex items-center space-x-2 px-8 py-4 bg-gradient-to-r 
                  from-green-500 to-emerald-600 text-white rounded-xl font-medium shadow-lg
                  hover:shadow-xl transition-shadow group"
              >
                <span>View Full Menu</span>
                <motion.div
                  animate={{ x: [0, 4, 0] }}
                  transition={{ 
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }}
                >
                  <FaArrowRight className="group-hover:transform group-hover:translate-x-1 
                    transition-transform" />
                </motion.div>
              </motion.button>
            </motion.div>
    
            {/* Filters Modal */}
            <AnimatePresence>
              {showFilters && (
                <FilterModal
                  isOpen={showFilters}
                  onClose={() => setShowFilters(false)}
                  filters={filterOptions}
                  activeFilters={activeFilters}
                  onFilterChange={(category, option, clear = false) => {
                    if (clear) {
                      setActiveFilters({});
                      return;
                    }
                    setActiveFilters(prev => ({
                      ...prev,
                      [category]: prev[category]?.includes(option)
                        ? prev[category].filter(item => item !== option)
                        : [...(prev[category] || []), option]
                    }));
                  }}
                />
              )}
            </AnimatePresence>
    
            {/* Notifications */}
            <AnimatePresence>
              {notification && (
                <Notification
                  message={notification.message}
                  type={notification.type}
                  onClose={() => setNotification(null)}
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      );
    };
    
    export default TrendingDishes;