import chickenBiryaniImg from '../images/chicken-biryani.jpg';
import hyderabadiBiryaniImg from '../images/hyderabadi-biryani.jpg';
import southIndianImg from '../images/south-indian.jpg';
import vegBiryaniImg from '../images/veg-biryani.jpg';
import kitchenImage1 from '../images/kitchen1.jpg';
import kitchenImage2 from '../images/kitchen2.jpg';
import kitchenImage3 from '../images/kitchen3.jpg';

export const cuisineColors = {
  'Chat & Snacks': 'from-orange-500 to-red-500',
  'Chinese': 'from-red-500 to-pink-500',
  'Chicken': 'from-yellow-500 to-orange-500',
  'South Indian': 'from-green-500 to-emerald-500'
};

export const filterOptions = {
  'Dietary': ['Vegetarian', 'Non-Vegetarian', 'Vegan'],
  'Spice Level': ['Mild', 'Medium', 'Spicy'],
  'Course': ['Starter', 'Main Course', 'Dessert'],
  'Preparation Time': ['Under 15 mins', '15-30 mins', 'Over 30 mins']
};

export const menuItems = [
  {
    category: 'Chat & Snacks',
    categoryImage: kitchenImage1,
    items: [
      {
        id: 'cs1',
        name: 'Chola Chat',
        description: 'Classic street food with chickpeas, spices, and tangy chutneys',
        price: { half: '₹60', full: '₹100' },
        image: kitchenImage1,
        rating: 4.5,
        preparationTime: 15,
        isVeg: true,
        spiceLevel: 'Medium',
        isNew: true,
        bestSeller: true,
        calories: 250,
        ingredients: ['Chickpeas', 'Onions', 'Tomatoes', 'Green Chutney', 'Tamarind Chutney']
      },
      {
        id: 'cs2',
        name: 'Chola Puri',
        description: 'Fluffy deep-fried bread served with spiced chickpea curry',
        price: { half: '₹50', full: '₹90' },
        image: kitchenImage2,
        rating: 4.3,
        preparationTime: 20,
        isVeg: true,
        spiceLevel: 'Medium',
        calories: 350,
        ingredients: ['Whole Wheat Flour', 'Chickpeas', 'Spices', 'Onions', 'Tomatoes']
      }
    ]
  },
  {
    category: 'Chicken',
    categoryImage: chickenBiryaniImg,
    items: [
      {
        id: 'ck1',
        name: 'Chicken Biryani',
        description: 'Aromatic rice dish with tender chicken and special spices',
        price: { half: '₹120', full: '₹220' },
        image: chickenBiryaniImg,
        rating: 4.7,
        preparationTime: 25,
        isVeg: false,
        spiceLevel: 'Spicy',
        bestSeller: true,
        calories: 400,
        ingredients: ['Basmati Rice', 'Chicken', 'Biryani Masala', 'Saffron']
      },
      {
        id: 'ck2',
        name: 'Hyderabadi Biryani',
        description: 'Traditional Hyderabadi style biryani with rich flavors',
        price: { half: '₹140', full: '₹260' },
        image: hyderabadiBiryaniImg,
        rating: 4.8,
        preparationTime: 30,
        isVeg: false,
        spiceLevel: 'Spicy',
        isNew: true,
        calories: 450,
        ingredients: ['Basmati Rice', 'Chicken', 'Hyderabadi Spices', 'Mint']
      }
    ]
  },
  {
    category: 'South Indian',
    categoryImage: southIndianImg,
    items: [
      {
        id: 'si1',
        name: 'Masala Dosa',
        description: 'Crispy rice crepe filled with spiced potato mixture',
        price: { half: '₹80', full: '₹150' },
        image: southIndianImg,
        rating: 4.6,
        preparationTime: 15,
        isVeg: true,
        spiceLevel: 'Medium',
        bestSeller: true,
        calories: 300,
        ingredients: ['Rice Batter', 'Potatoes', 'Spices', 'Chutney']
      },
      {
        id: 'si2',
        name: 'Veg Biryani',
        description: 'Flavorful rice preparation with mixed vegetables',
        price: { half: '₹100', full: '₹180' },
        image: vegBiryaniImg,
        rating: 4.5,
        preparationTime: 25,
        isVeg: true,
        spiceLevel: 'Medium',
        calories: 350,
        ingredients: ['Basmati Rice', 'Mixed Vegetables', 'Biryani Masala']
      }
    ]
  }
];

// Helper function to get all trending dishes
export const getTrendingDishes = () => {
  return menuItems
    .flatMap(category => 
      category.items
        .filter(item => item.bestSeller || item.isNew)
        .map(item => ({
          ...item,
          category: category.category,
          categoryImage: category.categoryImage
        }))
    )
    .sort((a, b) => b.rating - a.rating);
};

// Helper function to get image with fallback
export const getDishImage = (dish, category) => {
  if (!dish.image) {
    const categoryData = menuItems.find(item => item.category === category);
    return categoryData ? categoryData.categoryImage : kitchenImage1;
  }
  return dish.image;
};