import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { FaShoppingCart, FaHeart, FaStar, FaPercent, FaTimes, FaArrowLeft, FaArrowRight, FaSearch, FaQrcode, FaMoneyBillWave, FaCheckCircle, FaClock, FaUserCircle, FaLeaf, FaFire, FaAward, FaUtensils, FaGift } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Assume we have these images imported
import chickenBiryaniImg from '../images/chicken-biryani.jpg';
import hyderabadiBiryaniImg from '../images/hyderabadi-biryani.jpg';
import southIndianImg from '../images/south-indian.jpg';
import vegBiryaniImg from '../images/veg-biryani.jpg';
import kitchenImage1 from '../images/kitchen1.jpg';
import kitchenImage2 from '../images/kitchen2.jpg';
import kitchenImage3 from '../images/kitchen3.jpg';

const kitchens = [
  { 
    id: 1,
    name: 'Biryani Bliss', 
    image: kitchenImage1,
    cuisineType: 'Indian',
    rating: 4.5,
    specialty: 'Authentic Hyderabadi Biryani', 
    offer: '20% off on first order',
    description: 'Experience the rich flavors of Hyderabad with our aromatic biryanis, prepared using traditional recipes and finest ingredients.',
    chefRecommendation: 'Our Chef recommends trying the Hyderabadi Dum Biryani for an authentic taste of royalty.',
    menu: [
      { id: 'bb1', name: 'Chicken Biryani', price: 250, image: chickenBiryaniImg, description: 'Fragrant basmati rice cooked with tender chicken and aromatic spices.', vegetarian: false, spicy: true, popular: true, chefSpecial: true },
      { id: 'bb2', name: 'Veg Biryani', price: 200, image: vegBiryaniImg, description: 'A medley of vegetables and rice, infused with royal spices.', vegetarian: true, spicy: false, popular: true },
      { id: 'bb3', name: 'Hyderabadi Dum Biryani', price: 300, image: hyderabadiBiryaniImg, description: 'Slow-cooked biryani with layers of meat and rice, sealed with dough for maximum flavor.', vegetarian: false, spicy: true, popular: false, chefSpecial: true },
      { id: 'bb4', name: 'Egg Biryani', price: 180, image: vegBiryaniImg, description: 'Flavorful rice preparation with boiled eggs and special Hyderabadi masala.', vegetarian: false, spicy: false, popular: false }
    ]
  },
  { 
    id: 2,
    name: 'Dragon Wok', 
    image: kitchenImage2,
    cuisineType: 'Indo-Chinese',
    rating: 4.3,
    specialty: 'Sizzling Indo-Chinese', 
    offer: 'Free drink with every meal',
    description: 'Indulge in the perfect fusion of Indian and Chinese flavors, bringing you the best of both worlds in every bite.',
    chefRecommendation: 'Try our Sichuan Chilli Chicken for a spicy kick that will tantalize your taste buds.',
    menu: [
      { id: 'dw1', name: 'Chilli Chicken', price: 220, image: chickenBiryaniImg, description: 'Spicy and tangy chicken preparation, a crowd favorite.', vegetarian: false, spicy: true, popular: true, chefSpecial: true },
      { id: 'dw2', name: 'Veg Manchurian', price: 180, image: vegBiryaniImg, description: 'Crispy vegetable balls tossed in a flavorful manchurian sauce.', vegetarian: true, spicy: true, popular: true },
      { id: 'dw3', name: 'Hakka Noodles', price: 150, image: southIndianImg, description: 'Stir-fried noodles with crunchy vegetables and aromatic sauces.', vegetarian: true, spicy: false, popular: false },
      { id: 'dw4', name: 'Sichuan Fried Rice', price: 170, image: vegBiryaniImg, description: 'Spicy fried rice with a mix of vegetables and Sichuan peppers.', vegetarian: true, spicy: true, popular: false, chefSpecial: true }
    ]
  },
  { 
    id: 3,
    name: 'Dosa Delight', 
    image: kitchenImage3,
    cuisineType: 'South Indian',
    rating: 4.7,
    specialty: 'South Indian Specialties', 
    offer: 'Buy 2 Get 1 free on weekends',
    description: 'Savor the authentic tastes of South India with our crispy dosas, fluffy idlis, and flavorful chutneys.',
    chefRecommendation: 'Our Chef recommends the Mysore Masala Dosa for a unique blend of flavors.',
    menu: [
      { id: 'dd1', name: 'Masala Dosa', price: 120, image: southIndianImg, description: 'Crispy rice crepe filled with spiced potato mixture, served with chutney and sambar.', vegetarian: true, spicy: false, popular: true },
      { id: 'dd2', name: 'Idli Sambar', price: 100, image: southIndianImg, description: 'Steamed rice cakes served with lentil soup and coconut chutney.', vegetarian: true, spicy: false, popular: true },
      { id: 'dd3', name: 'Mysore Masala Dosa', price: 140, image: southIndianImg, description: 'Dosa with a spicy red chutney spread and potato filling.', vegetarian: true, spicy: true, popular: false, chefSpecial: true },
      { id: 'dd4', name: 'Rava Vada', price: 80, image: southIndianImg, description: 'Crispy semolina fritters served with coconut chutney and sambar.', vegetarian: true, spicy: false, popular: false }
    ]
  },
];

const OurKitchensPage = () => {
  const [activeKitchen, setActiveKitchen] = useState(0);
  const [cart, setCart] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({ vegetarian: false, spicy: false, popular: false, chefSpecial: false });
  const [visibleDishes, setVisibleDishes] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState('placed');
  const [checkoutStep, setCheckoutStep] = useState(0);
  const [formData, setFormData] = useState({ name: '', phone: '', address: '', email: '' });
  const [paymentMethod, setPaymentMethod] = useState('upi');
  const [activeMenuCategory, setActiveMenuCategory] = useState('All');
  const [favorites, setFavorites] = useState([]);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [dietaryPreferences, setDietaryPreferences] = useState([]);

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const addToCart = (item) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(cartItem => cartItem.id === item.id);
      if (existingItem) {
        return prevCart.map(cartItem =>
          cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
        );
      }
      return [...prevCart, { ...item, quantity: 1 }];
    });
  };

  const removeFromCart = (itemId) => {
    setCart(prevCart => prevCart.filter(item => item.id !== itemId));
  };

  const updateQuantity = (itemId, newQuantity) => {
    setCart(prevCart =>
      prevCart.map(item =>
        item.id === itemId ? { ...item, quantity: Math.max(0, newQuantity) } : item
      ).filter(item => item.quantity > 0)
    );
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleFilter = (filter) => {
    setFilters(prevFilters => ({ ...prevFilters, [filter]: !prevFilters[filter] }));
  };

  const filteredDishes = kitchens[activeKitchen].menu.filter((dish) => {
    const matchesSearch = dish.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = activeMenuCategory === 'All' || dish.category === activeMenuCategory;
    const matchesVegetarian = !filters.vegetarian || dish.vegetarian;
    const matchesSpicy = !filters.spicy || dish.spicy;
    const matchesPopular = !filters.popular || dish.popular;
    const matchesChefSpecial = !filters.chefSpecial || dish.chefSpecial;
    const matchesDietaryPreferences = dietaryPreferences.length === 0 || dietaryPreferences.every(pref => dish[pref]);
    return matchesSearch && matchesCategory && matchesVegetarian && matchesSpicy && matchesPopular && matchesChefSpecial && matchesDietaryPreferences;
  });

  const loadMoreDishes = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisibleDishes(prevVisible => prevVisible + 4);
      setIsLoading(false);
    }, 1000);
  };

  const toggleFavorite = (itemId) => {
    setFavorites(prevFavorites => 
      prevFavorites.includes(itemId)
        ? prevFavorites.filter(id => id !== itemId)
        : [...prevFavorites, itemId]
    );
  };

  const addLoyaltyPoints = (points) => {
    setLoyaltyPoints(prevPoints => prevPoints + points);
  };

  const toggleDietaryPreference = (preference) => {
    setDietaryPreferences(prevPreferences =>
      prevPreferences.includes(preference)
        ? prevPreferences.filter(pref => pref !== preference)
        : [...prevPreferences, preference]
    );
  };

  const KitchenCarousel = () => (
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      breakpoints={{
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      }}
      className="mb-12"
    >
      {kitchens.map((kitchen, index) => (
        <SwiperSlide key={kitchen.id}>
          <motion.div
            className={`bg-white rounded-lg shadow-md overflow-hidden cursor-pointer ${
              activeKitchen === index ? 'ring-2 ring-green-500' : ''
            }`}
            onClick={() => setActiveKitchen(index)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <img src={kitchen.image} alt={kitchen.name} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">{kitchen.name}</h3>
              <p className="text-sm text-gray-600 mb-2">{kitchen.cuisineType}</p>
              <div className="flex items-center">
                <FaStar className="text-yellow-400 mr-1" />
                <span>{kitchen.rating}</span>
              </div>
            </div>
          </motion.div>
        </SwiperSlide>
      ))}
    </Swiper>
  );


  const MenuTabs = () => (
    <div className="flex overflow-x-auto mb-6 pb-2">
      {['All', 'Appetizers', 'Main Course', 'Desserts'].map((category) => (
        <button
          key={category}
          className={`px-4 py-2 mr-2 rounded-full ${
            activeMenuCategory === category
              ? 'bg-green-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveMenuCategory(category)}
        >
          {category}
        </button>
      ))}
    </div>
  );

  const MenuItem = ({ item }) => (
    <motion.div
      className="bg-white rounded-lg shadow-md overflow-hidden"
      whileHover={{ scale: 1.05 }}
      layout
    >
      <img src={item.image} alt={item.name} className="w-full h-48 object-cover" />
      <div className="p-4">
        <div className="flex justify-between items-start mb-2">
          <h4 className="text-lg font-semibold">{item.name}</h4>
          <button onClick={() => toggleFavorite(item.id)}>
            <FaHeart className={favorites.includes(item.id) ? "text-red-500" : "text-gray-300"} />
          </button>
        </div>
        <p className="text-sm text-gray-600 mb-2">{item.description}</p>
        <div className="flex justify-between items-center">
          <span className="text-green-600 font-bold">₹{item.price}</span>
          <button
            onClick={() => addToCart(item)}
            className="bg-green-500 text-white px-3 py-1 rounded-full text-sm hover:bg-green-600 transition duration-300"
          >
            Quick Add
          </button>
        </div>
        <div className="mt-2 flex flex-wrap gap-2">
          {item.vegetarian && (
            <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
              <FaLeaf className="inline mr-1" /> Vegetarian
            </span>
          )}
          {item.spicy && (
            <span className="bg-red-100 text-red-800 text-xs px-2 py-1 rounded-full">
              <FaFire className="inline mr-1" /> Spicy
            </span>
          )}
          {item.popular && (
            <span className="bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded-full">
              <FaStar className="inline mr-1" /> Popular
            </span>
          )}
          {item.chefSpecial && (
            <span className="bg-purple-100 text-purple-800 text-xs px-2 py-1 rounded-full">
              <FaAward className="inline mr-1" /> Chef's Special
            </span>
          )}
        </div>
      </div>
    </motion.div>
  );

  const MiniCart = () => (
    <motion.div
      className="fixed bottom-4 left-4 bg-white rounded-lg shadow-lg p-4 max-w-sm w-full"
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
    >
      <h3 className="text-lg font-semibold mb-2">Your Cart</h3>
      {cart.length === 0 ? (
        <p className="text-gray-500">Your cart is empty</p>
      ) : (
        <>
          {cart.slice(0, 3).map((item) => (
            <div key={item.id} className="flex justify-between items-center mb-2">
              <span>{item.name} x {item.quantity}</span>
              <span>₹{item.price * item.quantity}</span>
            </div>
          ))}
          {cart.length > 3 && (
            <p className="text-sm text-gray-500">And {cart.length - 3} more items...</p>
          )}
          <div className="mt-4">
            <p className="font-semibold">Total: ₹{getTotalPrice()}</p>
            <button
              onClick={() => setCheckoutStep(1)}
              className="w-full bg-green-500 text-white py-2 rounded-full mt-2 hover:bg-green-600 transition duration-300"
            >
              Proceed to Checkout
            </button>
          </div>
        </>
      )}
    </motion.div>
  );

  const CheckoutProcess = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
        className="bg-white rounded-lg p-8 max-w-md w-full"
      >
        <h2 className="text-2xl font-bold mb-4">Checkout</h2>
        {checkoutStep === 1 && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Order Summary</h3>
            {cart.map((item) => (
              <div key={item.id} className="flex justify-between items-center mb-2">
                <span>{item.name} x {item.quantity}</span>
                <span>₹{item.price * item.quantity}</span>
              </div>
            ))}
            <div className="border-t pt-2 mt-2">
              <div className="flex justify-between items-center font-bold">
                <span>Total</span>
                <span>₹{getTotalPrice()}</span>
              </div>
            </div>
          </div>
        )}
        {checkoutStep === 2 && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Delivery Address</h3>
            <select
              className="w-full mb-4 p-2 border rounded"
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            >
              <option value="">Select an address</option>
              <option value="Home">Home</option>
              <option value="Work">Work</option>
              <option value="Other">Other</option>
            </select>
            {formData.address === 'Other' && (
              <textarea
                className="w-full p-2 border rounded"
                placeholder="Enter your address"
                value={formData.newAddress}
                onChange={(e) => setFormData({ ...formData, newAddress: e.target.value })}
              />
            )}
          </div>
        )}
        {checkoutStep === 3 && (
          <div>
            <h3 className="text-lg font-semibold mb-2">Payment Method</h3>
            <div className="flex justify-around mb-4">
              <button
                className={`flex items-center justify-center p-4 rounded-lg ${paymentMethod === 'upi' ? 'bg-green-100 border-2 border-green-500' : 'bg-gray-100'}`}
                onClick={() => setPaymentMethod('upi')}
              >
                <FaQrcode className="mr-2" /> UPI
              </button>
              <button
                className={`flex items-center justify-center p-4 rounded-lg ${paymentMethod === 'cash' ? 'bg-green-100 border-2 border-green-500' : 'bg-gray-100'}`}
                onClick={() => setPaymentMethod('cash')}
              >
                <FaMoneyBillWave className="mr-2" /> Cash
              </button>
            </div>
            {paymentMethod === 'upi' && (
              <div className="text-center">
                <img src="/upi-qr-code.png" alt="UPI QR Code" className="mx-auto mb-2" />
                <p className="text-sm text-gray-600">Scan this QR code to pay</p>
              </div>
            )}
          </div>
        )}
        {checkoutStep === 4 && (
          <div className="text-center">
            <FaCheckCircle className="text-green-500 text-5xl mb-4 mx-auto" />
            <h3 className="text-lg font-semibold mb-2">Order Confirmed!</h3>
            <p>Your order has been placed successfully.</p>
            <p className="mt-2">Order ID: ORD12345</p>
            <p className="mt-4">You've earned {Math.floor(getTotalPrice() * 0.1)} loyalty points!</p>
          </div>
        )}
        <div className="mt-6 flex justify-between">
          {checkoutStep > 1 && checkoutStep < 4 && (
            <button
              onClick={() => setCheckoutStep(prevStep => prevStep - 1)}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full hover:bg-gray-400 transition duration-300"
            >
              Back
            </button>
          )}
          {checkoutStep < 3 && (
            <button
              onClick={() => setCheckoutStep(prevStep => prevStep + 1)}
              className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300"
            >
              Next
            </button>
          )}
          {checkoutStep === 3 && (
            <button
              onClick={() => {
                setCheckoutStep(4);
                addLoyaltyPoints(Math.floor(getTotalPrice() * 0.1));
              }}
              className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300"
            >
              Place Order
            </button>
          )}
          {checkoutStep === 4 && (
            <button
              onClick={() => {
                setCheckoutStep(0);
                setCart([]);
              }}
              className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300"
            >
              Back to Menu
            </button>
          )}
        </div>
      </motion.div>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-emerald-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-12">Our Kitchens</h1>

        <KitchenCarousel />

        <AnimatePresence mode="wait">
          <motion.div
            key={activeKitchen}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-lg shadow-xl p-8 mb-12"
          >
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-3xl font-bold text-gray-900">{kitchens[activeKitchen].name}</h2>
              <span className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm font-semibold">
                <FaPercent className="inline mr-1" />
                {kitchens[activeKitchen].offer}
              </span>
            </div>
            <p className="text-gray-600 mb-6">{kitchens[activeKitchen].description}</p>
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
              <p className="text-sm text-yellow-700">
                <FaAward className="inline mr-2" />
                Chef's Recommendation: {kitchens[activeKitchen].chefRecommendation}
              </p>
            </div>

            <div className="mb-6 flex flex-wrap items-center gap-4">
              <div className="relative flex-grow">
                <input
                  type="text"
                  placeholder="Search dishes..."
                  className="w-full pl-10 pr-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
              <div className="flex space-x-2 overflow-x-auto pb-2">
                <button
                  onClick={() => toggleFilter('vegetarian')}
                  className={`px-3 py-1 rounded-full text-sm ${
                    filters.vegetarian ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`}
                >
                  <FaLeaf className="inline mr-1" /> Vegetarian
                </button>
                <button
                  onClick={() => toggleFilter('spicy')}
                  className={`px-3 py-1 rounded-full text-sm ${
                    filters.spicy ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`}
                >
                  <FaFire className="inline mr-1" /> Spicy
                </button>
                <button
                  onClick={() => toggleFilter('popular')}
                  className={`px-3 py-1 rounded-full text-sm ${
                    filters.popular ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`}
                >
                  <FaStar className="inline mr-1" /> Popular
                </button>
                <button
                  onClick={() => toggleFilter('chefSpecial')}
                  className={`px-3 py-1 rounded-full text-sm ${
                    filters.chefSpecial ? 'bg-purple-500 text-white' : 'bg-gray-200 text-gray-700'
                  }`}
                >
                  <FaAward className="inline mr-1" /> Chef's Special
                </button>
              </div>
            </div>

            <MenuTabs />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {filteredDishes.slice(0, visibleDishes).map((item) => (
                <MenuItem key={item.id} item={item} />
              ))}
            </div>

            {visibleDishes < filteredDishes.length && (
              <div className="mt-8 text-center">
                <button
                  onClick={loadMoreDishes}
                  className="bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition duration-300"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Load More'}
                </button>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        <section className="mt-16" ref={ref}>
          <motion.h2
            className="text-3xl font-bold text-gray-900 mb-8 text-center"
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 }
            }}
          >
            Featured Dishes
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {kitchens.flatMap(kitchen => kitchen.menu).filter(dish => dish.popular).slice(0, 3).map((dish, index) => (
              <MenuItem key={dish.id} item={dish} />
            ))}
          </div>
        </section>

        <section className="mt-16 bg-green-50 rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Special Offers</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-4">First-Time Order Discount</h3>
              <p className="text-gray-600 mb-4">Get 20% off on your first order with us!</p>
              <button className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300">
                Claim Offer
              </button>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-4">Refer a Friend</h3>
              <p className="text-gray-600 mb-4">Refer a friend and both get ₹100 off your next order!</p>
              <button className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300">
                Refer Now
              </button>
            </div>
          </div>
        </section>

        <section className="mt-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">What Our Customers Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { name: "Priya S.", comment: "The Hyderabadi Biryani is simply amazing! Authentic taste and quick delivery.", rating: 5 },
              { name: "Rahul M.", comment: "Love the variety of dishes. The Chilli Chicken is my favorite!", rating: 4 },
              { name: "Anita K.", comment: "Great service and delicious food. The Masala Dosa is a must-try!", rating: 5 },
            ].map((review, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-lg shadow-md p-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="flex items-center mb-4">
                  <div className="bg-green-500 text-white rounded-full w-10 h-10 flex items-center justify-center font-bold mr-3">
                    {review.name[0]}
                  </div>
                  <div>
                    <h3 className="font-semibold">{review.name}</h3>
                    <div className="flex">
                      {[...Array(5)].map((_, i) => (
                        <FaStar key={i} className={i < review.rating ? "text-yellow-400" : "text-gray-300"} />
                      ))}
                    </div>
                  </div>
                </div>
                <p className="text-gray-600">{review.comment}</p>
              </motion.div>
            ))}
          </div>
        </section>

        <section className="mt-16 bg-white rounded-lg shadow-xl p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Loyalty Program</h2>
          <div className="text-center">
            <p className="text-lg mb-4">Your current loyalty points: <span className="font-bold text-green-600">{loyaltyPoints}</span></p>
            <p className="mb-6">Earn 1 point for every ₹10 spent. Redeem your points for exclusive discounts and free dishes!</p>
            <button className="bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition duration-300">
              View Rewards
            </button>
          </div>
        </section>
      </div>

      <AnimatePresence>
        {cart.length > 0 && <MiniCart />}
      </AnimatePresence>

      <AnimatePresence>
        {checkoutStep > 0 && <CheckoutProcess />}
      </AnimatePresence>

      <motion.button
        className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => setCheckoutStep(1)}
      >
        <FaShoppingCart className="text-2xl" />
        {cart.length > 0 && (
          <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm">
            {cart.length}
          </span>
        )}
      </motion.button>

      <motion.button
        className="fixed bottom-20 right-4 bg-blue-500 text-white p-4 rounded-full shadow-lg"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => {/* Open order tracking modal */}}
      >
        <FaClock className="text-2xl" />
      </motion.button>

      <motion.button
        className="fixed bottom-36 right-4 bg-yellow-500 text-white p-4 rounded-full shadow-lg"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => {/* Open feedback modal */}}
      >
        <FaUserCircle className="text-2xl" />
      </motion.button>
    </div>
  );
};

export default OurKitchensPage;
