import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUtensils, FaMobileAlt, FaRocket, FaEnvelope, FaApple, FaGooglePlay, FaLeaf, FaHeart, FaStar } from 'react-icons/fa';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

const CountdownTimer = ({ launchDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(launchDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <div key={interval} className="flex flex-col items-center bg-white shadow-lg rounded-lg p-4 w-24">
      <span className="text-4xl font-bold text-green-600">{timeLeft[interval]}</span>
      <span className="text-sm uppercase text-gray-600">{interval}</span>
    </div>
  ));

  return (
    <div className="flex justify-center space-x-4">
      {timerComponents.length ? timerComponents : <span className="text-4xl font-bold text-green-600">Launching Now!</span>}
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    className="bg-white rounded-lg shadow-lg p-6 text-center"
    whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.1)" }}
    transition={{ duration: 0.3 }}
  >
    <motion.div
      className="text-5xl text-green-500 mb-4 mx-auto"
      whileHover={{ rotate: 360, scale: 1.2 }}
      transition={{ duration: 0.5 }}
    >
      {icon}
    </motion.div>
    <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const OrderPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const launchDate = "2024-09-05T00:00:00"; // September 5, 2024

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted email:', email);
    setIsSubmitted(true);
    setEmail('');
  };

  return (
    <ParallaxProvider>
      <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-blue-600 mb-4">
              Sai Green: Bokaro's Culinary Revolution
            </h1>
            <p className="text-2xl text-gray-600 mb-8">
              Launching on Teacher's Day, September 5th, 2024
            </p>
            <CountdownTimer launchDate={launchDate} />
          </motion.div>

          <Parallax y={[-20, 20]} tagOuter="figure">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <FeatureCard 
                icon={<FaUtensils />}
                title="Bokaro's Finest Cuisine"
                description="Savor a curated selection of local delicacies and global flavors, prepared by Bokaro's top chefs using the freshest ingredients."
              />
              <FeatureCard 
                icon={<FaMobileAlt />}
                title="Smart Ordering"
                description="Experience seamless ordering with our AI-powered app, tailored to Bokaro's unique tastes and preferences."
              />
              <FeatureCard 
                icon={<FaRocket />}
                title="Swift Delivery"
                description="Enjoy hot, fresh meals delivered to your doorstep in record time, thanks to our cutting-edge logistics network across Bokaro."
              />
            </div>
          </Parallax>

          <Parallax y={[-30, 30]} tagOuter="figure">
            <motion.div
              className="bg-white rounded-lg shadow-xl p-8 mb-16"
              whileHover={{ boxShadow: "0 15px 30px rgba(0,0,0,0.1)" }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Join Bokaro's Food Revolution</h2>
              <p className="text-xl text-gray-600 text-center mb-8">
                Be the first to experience Sai Green's innovative culinary offerings. Sign up now for exclusive launch day benefits!
              </p>
              <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                <div className="flex items-center border-b-2 border-green-500 py-2 mb-4">
                  <FaEnvelope className="text-green-500 mr-3" />
                  <input
                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="email"
                    placeholder="Enter your email"
                    aria-label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <motion.button
                    className="flex-shrink-0 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    type="submit"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Get VIP Access
                  </motion.button>
                </div>
              </form>
              <AnimatePresence>
                {isSubmitted && (
                  <motion.p
                    className="text-green-600 text-center mt-4"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    Thank you for joining! Get ready for a taste revolution in Bokaro.
                  </motion.p>
                )}
              </AnimatePresence>
            </motion.div>
          </Parallax>

          <motion.div
            className="mb-16"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">Why Sai Green?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <FeatureCard 
                icon={<FaLeaf />}
                title="Farm-Fresh Ingredients"
                description="We partner with local Bokaro farmers to bring you the freshest, most flavorful ingredients in every dish."
              />
              <FeatureCard 
                icon={<FaHeart />}
                title="Community-Centric"
                description="Sai Green is committed to Bokaro's growth. We create local jobs and support community initiatives."
              />
              <FeatureCard 
                icon={<FaStar />}
                title="Unmatched Quality"
                description="Experience restaurant-quality meals in the comfort of your home, prepared by Bokaro's finest chefs."
              />
            </div>
          </motion.div>

          <Parallax y={[-20, 20]} tagOuter="figure">
            <motion.div
              className="bg-gradient-to-r from-green-400 to-blue-500 rounded-lg shadow-xl p-8 text-white mb-16"
              whileHover={{ boxShadow: "0 15px 30px rgba(0,0,0,0.2)" }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-3xl font-bold text-center mb-6">Coming to Your Devices</h2>
              <p className="text-xl text-center mb-8">
                Get ready to experience Bokaro's finest cuisine at your fingertips. Our state-of-the-art mobile apps will be available for download on launch day!
              </p>
              <div className="flex justify-center space-x-4">
                <motion.button
                  className="flex items-center bg-black text-white px-6 py-3 rounded-full text-lg font-semibold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaApple className="mr-2" /> App Store
                </motion.button>
                <motion.button
                  className="flex items-center bg-white text-green-600 px-6 py-3 rounded-full text-lg font-semibold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaGooglePlay className="mr-2" /> Google Play
                </motion.button>
              </div>
            </motion.div>
          </Parallax>

          
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default OrderPage;